import { useContext, useEffect, useState } from 'react';
import './TrexDiagramOutput.scss';
import ReactFlow, { Controls, Elements, ReactFlowProvider, OnLoadParams } from 'react-flow-renderer';
import TrexStartNode from '../react-flow/nodes/TrexStartNode';
import TrexEndNode from '../react-flow/nodes/TrexEndNode';
import TrexGroup from '../react-flow/nodes/TrexGroup';
import TrexAnchor from '../react-flow/nodes/TrexAnchor';
import TrexAnyChar from '../react-flow/nodes/TrexAnyChar';
import TrexCharacterClass from '../react-flow/nodes/TrexCharacterClass';
import DiagramController from '../../../controller/diagramController';
import TrexRepetitorEdge from '../react-flow/edges/TrexRepetitorEdge';
import { RegEx } from '../../../model/regex';
import { ExpressionController } from '../../../controller/expressionController';
import { DevmodeContext } from '../../contexts/Contexts';
import TrexInvisibleNode from '../react-flow/nodes/TrexInvisibleNode';
import TrexDefaultEdge from '../react-flow/edges/TrexDefaultEdge';
import TrexSimpleText from '../react-flow/nodes/TrexSimpleText';

// Purpose: shows the RegEx in form of a diagram for better understanding

type DiagramOutputProps = {
    // same as TrexTreeOutput
    regEx: RegEx;
    expressionController: ExpressionController;
    autoFit: boolean;
    mockData?: number;
};

const nodeTypes = {
    anyChar: TrexAnyChar,
    simpleText: TrexSimpleText,
    startNode: TrexStartNode,
    endNode: TrexEndNode,
    group: TrexGroup,
    anchor: TrexAnchor,
    characterClass: TrexCharacterClass,
    invisible: TrexInvisibleNode,
    repetition: TrexInvisibleNode,
    diverge: TrexInvisibleNode,
    merge: TrexInvisibleNode,
};

const edgeTypes = {
    trexDefault: TrexDefaultEdge,
    repetition: TrexRepetitorEdge,
};

export default function TrexDiagramOutput(props: DiagramOutputProps) {
    const devMode = useContext(DevmodeContext);

    const [reactFlowRev, setReactFlowRev] = useState<OnLoadParams>();

    useEffect(() => {
        if (reactFlowRev) {
            fitView();
        }
    }, [reactFlowRev, props.regEx.expression, props.regEx.flavor]);

    const fitView = () => {
        if (reactFlowRev && props.autoFit) {
            setTimeout(() => reactFlowRev.fitView(), 0);
        }
    };

    const controller = new DiagramController(devMode.devMode ?? false);

    let elements: Elements = [];
    try {
        elements = controller.convertRegexToDiagram(
            props.expressionController.parseRegEx(props.regEx, false),
            props.mockData
        );
    } catch (error) {
        console.log('The diagramController threw an unexpected error:', error);

        return (
            <div className='trexDiagramOutput-wrapper'>
                <div className='trexDiagramOutput-error'>The diagram for this expression could not be rendered.</div>
            </div>
        );
    }

    return (
        <div className='trexDiagramOutput-wrapper'>
            <ReactFlowProvider>
                <ReactFlow
                    elements={elements}
                    elementsSelectable={false}
                    onLoad={(reactFlowInstance) => {
                        setReactFlowRev(reactFlowInstance);
                    }}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    paneMoveable={true}
                    // used, because edges are not rendering if the handles are out of screen
                    onlyRenderVisibleElements={false}
                    maxZoom={8}
                    minZoom={0.1}
                >
                    <Controls showInteractive={false} />
                </ReactFlow>
            </ReactFlowProvider>
        </div>
    );
}
