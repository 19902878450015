import React, { ChangeEvent, Component } from 'react';
import { Flag } from '../../../model/flag';
import TrexMultiSelectDropdown from '../misc/TrexMultiSelectDropdown';
import './TrexRegExInput.scss';
import { Flavor, FLAVOR_NAME } from '../../../model/flavor';
import { ExpressionController } from '../../../controller/expressionController';
import TrexIconButton from '../misc/TrexIconButton';
import TrexModal from './TrexFlavorModal';
import TrexDropdown from '../misc/TrexDropdown';
import CodeMirror from 'react-codemirror';
import HighlightingController from '../../../controller/highlightingController';
import { FiAlertTriangle } from 'react-icons/fi';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import { EngineError } from '@top/t-regex-parser';

// Purpose: An Input for the RegEx, also includes the components FlavorDropdown, FlagDropdown and TrexIconButton

type TrexRegExInputProps = {
    onChangeRegex: (newValue: string) => void;
    onChangeFlavor: (
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: React.ReactNode,
        callBack: () => void
    ) => void;
    onFlagsChange: (event: ChangeEvent<{ value: unknown }>) => void;
    value: string;
    selectedFlavor: Flavor;
    allFlags: Flag[];
    selectedFlags: Flag[];
    expressionController: ExpressionController;
    highlightingController: HighlightingController;
};

type TrexRegExInputState = {
    error: boolean | undefined;
    errorMessage: EngineError[];
    hover: boolean;
    flavorHelpOpen: boolean;
    copied: boolean;
    tooltipTitle: string;
    lastPasteRemove?: string;
};

export default class TrexRegExInput extends Component<TrexRegExInputProps, TrexRegExInputState> {
    constructor(props: TrexRegExInputProps) {
        super(props);
        this.state = {
            flavorHelpOpen: false,
            copied: false,
            tooltipTitle: '',
            error: false,
            hover: false,
            errorMessage: [],
        };
    }

    componentDidMount() {
        this.props.highlightingController.regexEditor = (this.refs
            .editor as ReactCodeMirror.ReactCodeMirror).getCodeMirror();
        this.props.highlightingController.updateRegExHighlighting(
            this.props.expressionController.parseRegEx({
                expression: this.props.value,
                flavor: this.props.selectedFlavor,
                flags: this.props.selectedFlags,
            }),
            this.props.expressionController.errorMessage,
            this.props.selectedFlavor
        );
    }

    getCopyText = (): string => {
        let copyFlags = '';
        const regExExpression = this.props.value.replaceAll('\\', '\\\\').replaceAll("'", "\\'");

        this.props.selectedFlags.map((flag) => {
            copyFlags += flag.flag;
        });

        switch (this.props.selectedFlavor) {
            case Flavor.JAVASCRIPT:
                return `/${regExExpression}/${copyFlags}`;
            case Flavor.BRE:
                return `grep${copyFlags ? ' -' : ''}${copyFlags} '${regExExpression}'`;
            case Flavor.ERE:
                return `egrep${copyFlags ? ' -' : ''}${copyFlags} '${regExExpression}'`;
            default:
                return regExExpression;
        }
    };

    render() {
        return (
            <div className='trexRegExInput-wrapper'>
                <div className='trexRegExInput-header'>
                    <span className='trexRegExInput-header-title'>Regular Expression</span>
                    <span className='trexRegExInput-header-mobileTitle'>RegEx</span>
                    <TrexDropdown
                        id='selectFlavor'
                        selectedOption={this.props.selectedFlavor}
                        onChange={(event, child) => {
                            this.props.onChangeFlavor(event, child, () => {
                                this.setState({
                                    error: this.props.expressionController.error,
                                    errorMessage: this.props.expressionController.errorMessage,
                                });
                            });
                        }}
                        values={this.props.expressionController.flavors.map((flavor) => FLAVOR_NAME[flavor])}
                    />
                    <div className='trexRegExInput-button-help'>
                        <TrexIconButton
                            name='Flavor help'
                            icon='FiHelpCircle'
                            size={18}
                            secondary={true}
                            onClick={() => {
                                this.setState({ flavorHelpOpen: true });
                            }}
                        />
                    </div>
                    <TrexModal
                        selectedFlavor={this.props.selectedFlavor}
                        open={this.state.flavorHelpOpen}
                        onClose={() => {
                            this.setState({ flavorHelpOpen: false });
                        }}
                    />
                </div>
                <div className='trexRegExInput-input'>
                    <Tooltip
                        title={
                            <span style={{ fontSize: 16, lineHeight: 1.5 }}>
                                {this.state.errorMessage.map((errorMessage) => (
                                    <React.Fragment key={`${errorMessage.errorType}${errorMessage.errorPosition}`}>
                                        <p>
                                            {errorMessage.message} (col {errorMessage.errorPosition})
                                        </p>
                                    </React.Fragment>
                                ))}
                            </span>
                        }
                        open={this.state.hover}
                        arrow
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 200 }}
                        placement='bottom-start'
                    >
                        <span
                            className='trexRegExInput-errorIcon'
                            style={{ visibility: this.state.error ? 'visible' : 'hidden' }}
                            onMouseEnter={() => {
                                this.setState({ hover: true });
                            }}
                            onMouseLeave={() => {
                                this.setState({ hover: false });
                            }}
                            onClick={() => {
                                setInterval(() => {
                                    this.setState({ hover: false });
                                }, 5000);
                                this.setState({ hover: true });
                            }}
                        >
                            <FiAlertTriangle color={'red'} size={24} />
                        </span>
                    </Tooltip>

                    <CodeMirror
                        value={this.props.value}
                        ref='editor'
                        className='trexRegExInput-inputText'
                        onChange={(newValue, change: CodeMirror.EditorChange) => {
                            const editor: CodeMirror.Editor = (this.refs
                                .editor as ReactCodeMirror.ReactCodeMirror).getCodeMirror();

                            // HACK: Saving cursor pos (and applying it later on), because setValue() resets it
                            const cursorPos = editor.getCursor();

                            // HACK: Saving history (and applying it later on), because setValue() resets it
                            const history = editor.getHistory();

                            // HACK: After pasting and replacing the whole regex, the old text got appended to the new text
                            // to prevent this we just ignore the next "+input" update
                            let fakeValue = newValue;
                            if (change.origin === 'paste' && change.removed) {
                                this.setState({ lastPasteRemove: change.removed[0] });
                            }
                            if (change.origin === '+input' && this.state.lastPasteRemove) {
                                editor.getDoc().setValue(this.props.value);
                                fakeValue = this.props.value;
                                this.setState({ lastPasteRemove: undefined });
                            } else {
                                editor.getDoc().setValue(newValue.replace(/\n/g, ''));
                                editor.setHistory(history);
                            }

                            editor.setCursor(cursorPos);
                            this.props.onChangeRegex(fakeValue);
                            this.setState({
                                error: this.props.expressionController.error,
                                errorMessage: this.props.expressionController.errorMessage,
                            });
                        }}
                        options={{
                            lineNumbers: false,
                            autofocus: true,
                            scrollbarStyle: 'null',
                        }}
                    />

                    {this.props.allFlags.length !== 0 && (
                        <div className='trexRegExInput-flagdropdown'>
                            <TrexMultiSelectDropdown
                                selectedFlavor={this.props.selectedFlavor}
                                selectedFlags={this.props.selectedFlags}
                                allFlags={this.props.allFlags}
                                onFlagsChange={this.props.onFlagsChange}
                            />
                        </div>
                    )}
                    <Tooltip
                        open={this.state.copied}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={this.state.tooltipTitle}
                        arrow
                    >
                        <div className='TrexShare-IconButtonWrapper'>
                            <TrexIconButton
                                tertiary
                                size={20}
                                icon='FiCopy'
                                title=''
                                onClick={() => {
                                    if (navigator.clipboard) {
                                        navigator.clipboard.writeText(this.getCopyText());
                                        this.setState({ tooltipTitle: 'Copied!', copied: true });
                                        const interval = setInterval(() => {
                                            this.setState({ copied: false });
                                            clearInterval(interval);
                                        }, 4000);
                                    } else {
                                        this.setState({
                                            tooltipTitle:
                                                'Due to browser restriction this is impossible. Try copy via "crtl + c"',
                                            copied: true,
                                        });
                                        const interval = setInterval(() => {
                                            this.setState({ copied: false });
                                            clearInterval(interval);
                                        }, 10000);
                                    }
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    }
}
