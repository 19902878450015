import React, { Component } from 'react';
import './TrexSideBarItem.scss';
import * as Feather from 'react-icons/fi';
import Tooltip from '@material-ui/core/Tooltip';

// Purpose: Sidebar-item, to merge the icon and text of a menu item

type SideBarProps = {
    icon: keyof typeof Feather;
    text: string;
    collapsed: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export default class TrexSideBarItem extends Component<SideBarProps> {
    render() {
        const fiIcon = Feather[this.props.icon];
        return (
            <Tooltip title={this.props.collapsed ? '' : this.props.text} placement='right' arrow>
                <div className='trexSideBarItem-wrapper' onClick={this.props.onClick}>
                    <div className='trexSideBarItem-icon'>{React.createElement(fiIcon, { size: 30 })}</div>
                    {this.props.collapsed ? (
                        <>
                            <span className='trexSideBarItem-text'>{this.props.text}</span>
                            <div className='trexSideBarItem-icon'>
                                <Feather.FiChevronRight size='30' />
                            </div>
                        </>
                    ) : null}
                </div>
            </Tooltip>
        );
    }
}
