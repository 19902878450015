import React, { Component } from 'react';
import './TrexIconButton.scss';
import * as Feather from 'react-icons/fi';
import { Tooltip } from '@material-ui/core';

// Purpose: A button that shows an icon instead of text

type IconButtonProps = {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    icon: keyof typeof Feather;
    title?: string;
    size?: number;
    secondary?: boolean;
    tertiary?: boolean;
    fullScreenButton?: boolean;
    modal?: boolean;
    mobile?: boolean;
    name?: string;
    directionRight?: boolean;
};

export default class TrexIconButton extends Component<IconButtonProps> {
    render() {
        const fiIcon = Feather[this.props.icon];
        let tooltipName;
        if (this.props.name) {
            tooltipName = this.props.name;
        }
        let classNames = 'trexIconButton-button';
        if (this.props.title) {
            classNames += '-title';
        }
        if (this.props.secondary) {
            classNames += '-secondary';
        }
        if (this.props.modal) {
            classNames += '-modal';
        }
        if (this.props.tertiary) {
            classNames += '-tertiary';
        }
        if (this.props.fullScreenButton) {
            classNames += '-fullScreenButton';
        }
        if (this.props.mobile) {
            classNames += '-mobile';
        }
        return (
            <Tooltip
                title={tooltipName ? tooltipName : ''}
                arrow
                placement={this.props.directionRight ? 'right' : 'bottom'}
            >
                <button className={classNames} onClick={this.props.onClick}>
                    {React.createElement(fiIcon, { size: this.props.size ?? 30 })}
                    <span className='trexIconButton-title'>{this.props.title}</span>
                </button>
            </Tooltip>
        );
    }
}
