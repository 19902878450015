export class Controller {
    protected _devMode: boolean;

    constructor(devMode: boolean) {
        this._devMode = devMode;
    }

    get devMode(): boolean {
        return this._devMode;
    }
}
