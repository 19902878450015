import React, { Component } from 'react';
import './TrexGroup.scss';
import { Handle, Position } from 'react-flow-renderer';

type TrexGroupProps = {
    data: {
        label: string;
        groupId: number;
        relSourceHandlePos: number;
        relTargetHandlePos: number;
    };
};

export default class TrexGroup extends Component<TrexGroupProps> {
    static getMinWidth(label: string): number {
        return label.length * 22;
    }

    render() {
        return (
            <fieldset className={'trexGroup-wrapper' + (this.props.data.groupId % 5)}>
                <Handle
                    className='trexGroup-handle'
                    id='t1'
                    type='target'
                    position={Position.Left}
                    hidden={false}
                    style={{ cursor: 'default', top: this.props.data.relTargetHandlePos + '%' }}
                />
                <Handle
                    className='trexGroup-handle'
                    id='t2'
                    type='target'
                    position={Position.Right}
                    hidden={false}
                    style={{ cursor: 'default', top: this.props.data.relSourceHandlePos + '%' }}
                />
                <legend className={'trexGroup-legend' + (this.props.data.groupId % 5)}>{this.props.data.label}</legend>
                <Handle
                    className='trexGroup-handle'
                    id='s1'
                    type='source'
                    position={Position.Right}
                    hidden={false}
                    style={{ cursor: 'default', top: this.props.data.relSourceHandlePos + '%' }}
                />
                <Handle
                    className='trexGroup-handle'
                    id='s2'
                    type='source'
                    position={Position.Left}
                    hidden={false}
                    style={{ cursor: 'default', top: this.props.data.relTargetHandlePos + '%' }}
                />
            </fieldset>
        );
    }
}
