import React, { useContext, useState } from 'react';
import TrexIconButton from '../misc/TrexIconButton';
import TrexCheatSheet from './TrexCheatSheet';
import TrexSettings from './TrexSettings';
import TrexShare from './TrexShare';
import './TrexSideBar.scss';
import TrexSideBarItem from './TrexSideBarItem';
import { DevmodeContext } from '../../contexts/Contexts';
import { SwipeCallback, useSwipeable } from 'react-swipeable';
import { ThemeController } from '../../../controller/themeController';
import packageInfo from '../../../../package.json';
import { version as parserVersion } from '@top/t-regex-parser';
import { RegEx } from '../../../model/regex';

// Purpose: A Sidebar, that can be opened. It is a Menu that can be used to select the TrexShare, Option or TrexCheatSheet Component

enum MenuItem {
    MENU = 'Menu',
    CHEATSHEET = 'Cheatsheet',
    SETTINGS = 'Settings',
    SHARE = 'Share',
}

type SideBarProps = {
    regEx: RegEx;
    text: string;
    opened: boolean;
    onOpenChange: (opend: boolean) => void;
    themeController: ThemeController;
    onChangeTheme: () => void;
    onChangeAutoFitDiagram: (value: boolean) => void;
    onSlide: SwipeCallback;
    defaultAutoFitDiagram: boolean;
};

export default function TrexSidebar(props: SideBarProps) {
    const devMode = useContext(DevmodeContext);
    const [selectedMenuItem, setSelectedMenuItem] = useState(MenuItem.MENU);
    const swipeHandler = useSwipeable({
        onSwipedLeft: props.onSlide,
        onSwipedRight: props.onSlide,
        preventDefaultTouchmoveEvent: true,
    });

    const renderMenuItems = () => (
        <div className='trexSideBar-items'>
            <TrexSideBarItem
                icon='FiShare'
                collapsed={props.opened}
                text='Share'
                onClick={() => {
                    props.onOpenChange(true);
                    setSelectedMenuItem(MenuItem.SHARE);
                }}
            />
            <TrexSideBarItem
                icon='FiFileText'
                collapsed={props.opened}
                text='Cheatsheet'
                onClick={() => {
                    props.onOpenChange(true);
                    setSelectedMenuItem(MenuItem.CHEATSHEET);
                }}
            />
            <TrexSideBarItem
                icon='FiSettings'
                collapsed={props.opened}
                text='Settings'
                onClick={() => {
                    props.onOpenChange(true);
                    setSelectedMenuItem(MenuItem.SETTINGS);
                }}
            />
            {props.opened && (
                <div className='footer'>
                    <p className='legal'>
                        <a href='https://gitlab.ai.it.hs-worms.de/pub/impressum-und-datenschutzerklaerung'>
                            Imprint and Privacy
                        </a>
                    </p>
                    <p className='version'>
                        Frontend: <span>{packageInfo.version}</span>, Parser:
                        <span> {parserVersion}</span>
                    </p>
                </div>
            )}
        </div>
    );

    const switchRenderContent = () => {
        if (props.opened) {
            switch (selectedMenuItem) {
                case MenuItem.MENU:
                    return renderMenuItems();
                case MenuItem.SHARE:
                    return <TrexShare regEx={props.regEx} text={props.text} />;
                case MenuItem.CHEATSHEET:
                    return <TrexCheatSheet flavor={props.regEx.flavor} />;
                case MenuItem.SETTINGS:
                    return (
                        <TrexSettings
                            themeController={props.themeController}
                            onChangeTheme={props.onChangeTheme}
                            onChangeAutoFitDiagram={props.onChangeAutoFitDiagram}
                            defaultAutoFitDiagram={props.defaultAutoFitDiagram}
                        />
                    );
                default:
                    break;
            }
        } else {
            return renderMenuItems();
        }
    };

    return (
        <nav
            className={
                props.opened ? 'trexSideBar-nav trexSideBar-nav-extended' : 'trexSideBar-nav trexSideBar-nav-collapsed'
            }
            {...swipeHandler}
        >
            <div className='trexSideBar-header'>
                <div className='trexSideBar-headerIcon'>
                    <TrexIconButton
                        directionRight
                        icon={props.opened && selectedMenuItem !== MenuItem.MENU ? 'FiChevronLeft' : 'FiMenu'}
                        onClick={() => {
                            if (props.opened && selectedMenuItem !== MenuItem.MENU) {
                                setSelectedMenuItem(MenuItem.MENU);
                            } else if (props.opened && selectedMenuItem === MenuItem.MENU) {
                                props.onOpenChange(false);
                            } else {
                                props.onOpenChange(true);
                            }
                        }}
                        mobile={!props.opened}
                    />
                </div>

                {props.opened ? (
                    <>
                        <span className='trexSideBar-heading'>{selectedMenuItem}</span>
                    </>
                ) : null}

                {props.opened && selectedMenuItem !== MenuItem.MENU ? (
                    <>
                        <TrexIconButton
                            icon='FiX'
                            onClick={() => {
                                props.onOpenChange(false);
                            }}
                        />
                    </>
                ) : null}
            </div>
            {switchRenderContent()}
        </nav>
    );
}
