import React from 'react';
import './TrexDropdown.scss';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Purpose: A dropdown to select the used flavor (javascript, pearl, etc)

type DropdownProps = {
    id: string;
    selectedOption: number;
    onChange:
        | ((event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>, child: React.ReactNode) => void)
        | undefined;
    values: string[];
    disableUnderline?: boolean;
    title?: string;
};

export default function TrexDropdown(props: DropdownProps) {
    const renderSelect = () => (
        <Select
            style={{ width: 'auto' }}
            className='trexDropdown-select'
            id={'trexDropdown-select-' + props.id}
            value={props.selectedOption}
            onChange={props.onChange}
            disableUnderline={props.disableUnderline ?? true}
        >
            {props.values.map((value, index) => (
                <MenuItem value={index} key={index}>
                    {value}
                </MenuItem>
            ))}
        </Select>
    );

    if (props.title) {
        return <FormControlLabel control={renderSelect()} label={props.title} />;
    }
    return renderSelect();
}
