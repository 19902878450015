import './TrexRepetitorEdge.scss';
import { EdgeProps, EdgeText } from 'react-flow-renderer';

const TrexRepetitor = (sourceX: number, sourceY: number, targetX: number, targetY: number, height: number): string => {
    let path = 'M ';

    path +=
        targetX -
        20 +
        ' ' +
        targetY +
        ' ' +
        'L' +
        (targetX - 20) +
        ' ' +
        (targetY + height) +
        ' ' +
        'L' +
        (sourceX + 20) +
        ' ' +
        (targetY + height) +
        ' ' +
        'L' +
        (sourceX + 20) +
        ' ' +
        targetY;

    return path;
};

const TrexArrowPath = (sourceX: number, sourceY: number, targetX: number, targetY: number, height: number): string => {
    let arrow = 'M';

    arrow +=
        targetX -
        30 +
        ' ' +
        (targetY + 12) +
        ' ' +
        'L' +
        (targetX - 20) +
        ' ' +
        (targetY + 1) +
        'L' +
        (targetX - 10) +
        ' ' +
        (targetY + 12);

    return arrow;
};

const renderRepetitorText = (text: string, distanceNode: number, textPosX: number, targetY: number, height: number) => {
    if (text.length > 0) {
        return distanceNode > 70 ? (
            <EdgeText
                className='TrexRepetitionEdge-Text'
                x={textPosX}
                y={targetY + height}
                label={text}
                labelStyle={{ fontSize: '15px' }}
            />
        ) : (
            <EdgeText
                className='TrexRepetitonEdge-Text'
                x={textPosX}
                y={targetY + height + 15}
                label={text}
                labelStyle={{ fontSize: '15px' }}
            />
        );
    }
};

export default function TrexRepetitorEdge({ id, sourceX, sourceY, targetX, targetY, data }: EdgeProps) {
    const path = TrexRepetitor(sourceX, sourceY, targetX, targetY, data.height);
    const arrowPath = TrexArrowPath(sourceX, sourceY, targetX, targetY, data.height);
    let text: string = '';
    const textPosX = (targetX + sourceX) / 2;
    const interval = data.repetition.split('-', 2);
    if (interval.length > 1) {
        if (interval[0] > interval[1]) return;
        if (interval[0] === interval[1]) text = interval[0] + ' times';
        else {
            text = interval[0] + ' to ' + interval[1] + ' times';
        }
    }
    const distanceNode: number = sourceX - targetX;
    return (
        <>
            <path id={id} className='react-flow__edge-path-repetition' d={path} fill='none' />
            <path d={arrowPath} className='react-flow__edge-path' />
            {renderRepetitorText(text, distanceNode, textPosX, targetY, data.height)}
        </>
    );
}
