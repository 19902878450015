import { Controller } from './controller';
import { Match } from '../model/match';
import CodeMirror from 'react-codemirror';
import { EngineError, EngineErrorType, Node, NodeType, RootNode } from '@top/t-regex-parser';
import { Flavor } from '../model/flavor';

type Highlightings = {
    groups: Node[];
    characterSets: Node[];
    characterSetLengths: number[];
    specialTokens: number[];
};

type GroupHighlighting = {
    groupStart: number;
    groupEnd: number;
};

export default class HighlightingController extends Controller {
    private _matchTextEditor: CodeMirror.Editor | undefined;
    private _regexEditor: CodeMirror.Editor | undefined;
    private _regexEditorModal: CodeMirror.Editor | undefined;

    get matchTextEditor(): CodeMirror.Editor | undefined {
        return this._matchTextEditor;
    }

    set matchTextEditor(editor: CodeMirror.Editor | undefined) {
        this._matchTextEditor = editor;
    }

    get regexEditor(): CodeMirror.Editor | undefined {
        return this._regexEditor;
    }

    set regexEditor(editor: CodeMirror.Editor | undefined) {
        this._regexEditor = editor;
    }

    get regexEditorModal(): CodeMirror.Editor | undefined {
        return this._regexEditorModal;
    }

    set regexEditorModal(editorModal: CodeMirror.Editor | undefined) {
        this._regexEditorModal = editorModal;
    }

    public getGroupHighlightingColor(groupId: number): number {
        return groupId % 5;
    }

    public getMatchHighlightingColor(matchId: number): number {
        return matchId % 2;
    }

    public updateRegExHighlighting(tree: RootNode | undefined, errors: EngineError[], flavor: Flavor): void {
        if (!this.regexEditor) return;

        if (!tree) {
            this.clearRegexHighlighting();
            return;
        }

        const highlightings = this.parseTree(tree, tree, {
            groups: [],
            characterSetLengths: [],
            characterSets: [],
            specialTokens: [],
        });

        this.applyHighlightings(highlightings);
        this.applyErrorHighlightings(errors, flavor, this.regexEditor.getValue());
    }

    public updateMatchHighlighting(matches: Match[]): void {
        if (!this.matchTextEditor) return;

        this.clearMatchHighlighting();

        matches.forEach((match: Match, matchId) => {
            this.matchTextEditor
                ?.getDoc()
                .markText(
                    { line: match.lineNum - 1, ch: match.startPosition },
                    { line: match.lineNum - 1, ch: match.endPosition },
                    { className: 'CodeMirrorHighlighting-matchHighlighted' + this.getMatchHighlightingColor(matchId) }
                );
            match.matchGroups?.forEach((matchGroup) => {
                this.matchTextEditor?.getDoc().markText(
                    { line: matchGroup.lineNum - 1, ch: matchGroup.startPos },
                    { line: matchGroup.lineNum - 1, ch: matchGroup.endPos },
                    {
                        className:
                            'CodeMirrorHighlighting-groupHighlighted' +
                            this.getGroupHighlightingColor(matchGroup.groupNumber!),
                    }
                );
            });
        });
    }

    private clearRegexHighlighting() {
        if (!this.regexEditor) return;

        this.regexEditor
            .getDoc()
            .getAllMarks()
            .forEach((value) => value.clear());
    }

    private clearMatchHighlighting() {
        if (!this.matchTextEditor) return;

        this.matchTextEditor
            .getDoc()
            .getAllMarks()
            .forEach((value) => value.clear());
    }

    private parseTree(tree: RootNode, node: Node, highlightings: Highlightings): Highlightings {
        let endPos;
        let startPos;

        if (!node) return highlightings;

        switch (node.type) {
            case NodeType.GROUP:
                highlightings.groups.push(node);
                break;
            case NodeType.CHARACTERCLASS:
                highlightings.characterSets.push(node);
                highlightings.characterSetLengths.push(node.content.length);
                break;
            case NodeType.ANYCHAR:
                highlightings.specialTokens.push(node.position);
                break;
            case NodeType.NEGATION:
                highlightings.specialTokens.push(node.position + 1);
                break;
            case NodeType.ANCHORING:
                if (node.content.length === 1) highlightings.specialTokens.push(node.position);
                else {
                    highlightings.specialTokens.push(node.position);
                    //TODO: May work in the future, but currently the parser fails with the content length
                    highlightings.specialTokens.push(node.position + node.content.length);
                }
                break;
            case NodeType.POSIXCLASS:
                endPos = node.position + node.content.length;
                startPos = node.position;

                for (let i = startPos; i < endPos; i++) {
                    highlightings.specialTokens.push(i);
                }
                break;
            case NodeType.REPETITION:
                if (node.content.endsWith('}')) {
                    let currIndex = node.position + node.content.length - 1;
                    let highlightIndex = node.position;
                    while (currIndex >= 0) {
                        highlightings.specialTokens.push(highlightIndex++);
                        if (node.content.charAt(currIndex - node.position) === '{') break;
                        currIndex--;
                    }
                } else {
                    highlightings.specialTokens.push(node.position);
                }
                break;
        }

        if (node.hasChildren()) {
            for (const childNode of node.children) {
                highlightings = this.parseTree(tree, childNode, highlightings);
            }
        }

        return highlightings;
    }

    private applyHighlightings(highlightings: Highlightings): void {
        if (!this.regexEditor || !this.regexEditorModal) return;

        this.clearEditor(this.regexEditor);
        this.clearEditor(this.regexEditorModal);

        for (let groupId = 0; groupId < highlightings.groups.length; groupId++) {
            const groupHighlightings = this.getSingleGroupMatch(highlightings.groups, groupId);
            groupHighlightings.forEach((groupHighlighting) => {
                this.groupHighlighting(this.regexEditor!, groupHighlighting, groupId);
                this.groupHighlighting(this.regexEditorModal!, groupHighlighting, groupId);
            });
        }

        highlightings.specialTokens.forEach((position) => {
            this.specialTokenHighlighting(this.regexEditor!, position);
            this.specialTokenHighlighting(this.regexEditorModal!, position);
        });

        highlightings.characterSets.forEach((characterSet, index) => {
            this.characterSetsHighlighting(this.regexEditor!, characterSet, highlightings, index);
            this.characterSetsHighlighting(this.regexEditorModal!, characterSet, highlightings, index);
        });
    }

    private getSingleGroupMatch(groups: Node[], targetGroup: number): GroupHighlighting[] {
        const group = groups[targetGroup];
        const groupHighlighting: GroupHighlighting[] = [
            {
                groupStart: group.position,
                groupEnd: group.position + group.content.length,
            },
        ];
        for (let i = targetGroup + 1; i < groups.length; i++) {
            const currGroup = groups[i];
            const lastGroupHighlighting = groupHighlighting[groupHighlighting.length - 1];
            if (currGroup.position > group.position + group.content.length) break;
            if (
                currGroup.position <= lastGroupHighlighting.groupEnd &&
                currGroup.position >= lastGroupHighlighting.groupStart
            ) {
                lastGroupHighlighting.groupEnd = currGroup.position;
                groupHighlighting.push({
                    groupStart: currGroup.position + currGroup.content.length,
                    groupEnd: group.position + group.content.length,
                });
            }
        }
        return groupHighlighting;
    }

    private clearEditor(editor: CodeMirror.Editor) {
        editor
            .getDoc()
            .getAllMarks()
            .forEach((value) => value.clear());
    }

    private groupHighlighting(editor: CodeMirror.Editor, groupHighlighting: GroupHighlighting, groupId: number) {
        editor
            ?.getDoc()
            .markText(
                editor?.getDoc().posFromIndex(groupHighlighting.groupStart),
                editor?.getDoc().posFromIndex(groupHighlighting.groupEnd),
                {
                    className: 'CodeMirrorHighlighting-groupHighlighted' + this.getGroupHighlightingColor(groupId),
                }
            );
    }

    private specialTokenHighlighting(editor: CodeMirror.Editor, position: number) {
        editor
            ?.getDoc()
            .markText(editor?.getDoc().posFromIndex(position), editor?.getDoc().posFromIndex(position + 1), {
                className: 'CodeMirrorHighlighting-otherToken',
            });
    }
    private characterSetsHighlighting(
        editor: CodeMirror.Editor,
        characterSet: Node,
        highlightings: Highlightings,
        index: number
    ) {
        editor
            ?.getDoc()
            .markText(
                editor?.getDoc().posFromIndex(characterSet.position),
                editor?.getDoc().posFromIndex(characterSet.position + highlightings.characterSetLengths[index]),
                { className: 'CodeMirrorHighlighting-characterSet' }
            );
    }

    private applyErrorHighlightings(errors: EngineError[], flavor: Flavor, expression: string) {
        if (errors.length === 0) return;

        errors.forEach((error) => {
            let endPos = error.errorPosition + 1;
            let startPos = error.errorPosition;
            if (
                (error.errorType === EngineErrorType.GROUPMISSINGEND ||
                    error.errorType === EngineErrorType.INVALIDREPNOEND) &&
                flavor == Flavor.BRE
            ) {
                endPos++;
            } else if (error.errorType === EngineErrorType.CHARRANGEWRONGORDER) {
                startPos--;
                endPos++;
            } else if (error.errorType === EngineErrorType.INVALIDREPNOCONTENT) {
                if (flavor == Flavor.ERE) {
                    startPos--;
                } else if (flavor == Flavor.BRE) {
                    startPos -= 3;
                }
            } else if (
                error.errorType === EngineErrorType.INVALIDREPCONTENT ||
                error.errorType === EngineErrorType.INVALIDREPWRONGORDER
            ) {
                do {
                    startPos--;
                } while (expression[startPos] !== '{');
                if (flavor == Flavor.BRE) {
                    startPos--;
                    if (error.errorType === EngineErrorType.INVALIDREPCONTENT) {
                        endPos += 2;
                    }
                }
            } else if (error.errorType === EngineErrorType.INVALIDREPTOOMUCHCOMMA) {
                do {
                    endPos++;
                } while (expression[endPos] !== '}' && endPos < expression.length);
                if (flavor == Flavor.BRE) {
                    endPos--;
                }
            }
            this.regexEditor
                ?.getDoc()
                .markText(
                    this.regexEditor?.getDoc().posFromIndex(startPos),
                    this.regexEditor?.getDoc().posFromIndex(endPos),
                    { className: 'CodeMirrorHighlighting-error' }
                );
        });
    }
}
