import React, { ChangeEvent, Component } from 'react';
import './TrexMainPage.scss';
import TrexRegExInput from '../components/mainpage/TrexRegExInput';
import TrexTextInput from '../components/mainpage/TrexTextInput';
import TrexMatchOutput from '../components/mainpage/TrexMatchOutput';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import { ExpressionController } from '../../controller/expressionController';
import { Flavor } from '../../model/flavor';
import TrexDetails from '../components/mainpage/TrexDetails';
import { Flag } from '../../model/flag';
import { DevmodeContext } from '../contexts/Contexts';
import { Match } from '../../model/match';
import HighlightingController from '../../controller/highlightingController';
import { RegEx } from '../../model/regex';

// Purpose: The main page that is shown and stores the states

type TrexMainPageProps = {
    regex: RegEx;
    inputText: string;
    onChangeRegex: (newValue: string) => void;
    onChangeInputText: (newValue: string) => void;
    onFlagsChange: (event: ChangeEvent<{ value: unknown }>) => void;
    allFlags: Flag[];
    selectedFlags: Flag[];
    matches: Match[];
    onChangeFlavor: (
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: React.ReactNode,
        callBack: () => void
    ) => void;
    selectedFlavor: Flavor;
    expressionController: ExpressionController;
    autoFitDiagram: boolean;
    highlightingController: HighlightingController;
};

export default class TrexMainPage extends Component<TrexMainPageProps> {
    static contextType = DevmodeContext;

    constructor(props: TrexMainPageProps) {
        super(props);
    }

    onFlagsChange = (event: ChangeEvent<{ value: unknown }>) => {
        this.props.onFlagsChange(event);
    };

    onFlavorChange = (
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: React.ReactNode,
        callBack: () => void
    ) => {
        this.props.onChangeFlavor(event, child, callBack);
    };

    onChangeRegex = (newValue: string) => {
        this.props.onChangeRegex(newValue);
    };

    render() {
        return (
            <>
                <TrexRegExInput
                    onChangeRegex={this.onChangeRegex}
                    onChangeFlavor={this.onFlavorChange}
                    value={this.props.regex.expression}
                    selectedFlags={this.props.selectedFlags}
                    allFlags={this.props.allFlags}
                    onFlagsChange={this.onFlagsChange}
                    selectedFlavor={this.props.selectedFlavor}
                    expressionController={this.props.expressionController}
                    highlightingController={this.props.highlightingController}
                />
                <div className='TrexMainPage-splitterWrapperDiv'>
                    <SplitterLayout
                        customClassName='TrexMainPage-splitterWrapper'
                        percentage={true}
                        secondaryInitialSize={document.documentElement.clientHeight < 780 ? 65 : 50}
                        primaryMinSize={20}
                        secondaryMinSize={20}
                        vertical={true}
                    >
                        <SplitterLayout
                            customClassName='TrexMainPage-MatchTextOutputSplitter'
                            percentage={true}
                            primaryMinSize={20}
                            secondaryMinSize={20}
                        >
                            <TrexTextInput
                                onChange={(newValue) => {
                                    this.props.onChangeInputText(newValue);
                                }}
                                value={this.props.inputText}
                                matches={this.props.matches}
                                highlightingController={this.props.highlightingController}
                            />
                            <TrexMatchOutput matches={this.props.matches} />
                        </SplitterLayout>
                        <TrexDetails
                            onChangeRegex={this.props.onChangeRegex}
                            highlightingController={this.props.highlightingController}
                            selectedFlags={this.props.selectedFlags}
                            selectedFlavor={this.props.selectedFlavor}
                            regEx={this.props.regex}
                            expressionController={this.props.expressionController}
                            autoFitDiagram={this.props.autoFitDiagram}
                            inputText={this.props.inputText}
                        />
                    </SplitterLayout>
                </div>
            </>
        );
    }
}
