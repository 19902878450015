import React, { Component } from 'react';
import './TrexTextInput.scss';
import CodeMirror from 'react-codemirror';
import { Match } from '../../../model/match';
import HighlightingController from '../../../controller/highlightingController';

// Purpose: Allows the user to input a text on which the regEx is supposed to find matches

type TrexTextInputProps = {
    onChange: (newValue: string) => void;
    value: string;
    matches: Match[];
    highlightingController: HighlightingController;
};

export default class TrexTextInput extends Component<TrexTextInputProps> {
    constructor(props: TrexTextInputProps) {
        super(props);
    }

    componentDidMount() {
        this.props.highlightingController.matchTextEditor = (this.refs
            .editor as ReactCodeMirror.ReactCodeMirror).getCodeMirror();
        this.props.highlightingController.updateMatchHighlighting(this.props.matches);
    }

    render() {
        return (
            <div className='trexTextInput-wrapper'>
                <div className='trexTextInput-header'>Text</div>
                <div className='trexTextInput-areaWrapper'>
                    <CodeMirror
                        value={this.props.value}
                        ref='editor'
                        className='trexTextInput-textarea'
                        onChange={this.props.onChange}
                        options={{
                            lineWrapping: true,
                            lineNumbers: false,
                            autofocus: true,
                            mode: '',
                        }}
                    />
                </div>
            </div>
        );
    }
}
