/**
 * set cookie if the user give his agreement
 *
 * @param name
 * @param val
 * @returns
 */
export function setCookies(name: string, val: string): void {
    if (name !== 'cookiesApproved' && getCookies('cookiesApproved') !== 'true') {
        return;
    }
    const date = new Date();

    // Set it expire in 6 Months
    date.setTime(date.getTime() + 183 * 24 * 60 * 60 * 1000);

    document.cookie = name + '=' + val + '; expires=' + date.toUTCString() + '; samesite=strict; secure ; path=/';
}
/**
 * return the value of a cookie if available
 * @param name
 * @returns
 */
export function getCookies(name: string): string {
    const value: string = '; ' + document.cookie;

    //Check if Cookies are accept by the User
    const tests: string[] = value.split('; ' + 'cookiesApproved' + '=');
    let valTest: string | undefined = '';
    if (tests.length == 2) {
        const value = tests.pop();
        if (value) {
            valTest = value.split(';').shift();
            if (valTest !== 'true') {
                return '';
            }
        }
    }

    //Get cookie
    const parts: string[] = value.split('; ' + name + '=');
    let val: string | undefined = '';
    if (parts.length == 2) {
        const value = parts.pop();
        if (value) {
            val = value.split(';').shift();
            if (val) return val;
        }
    }

    return '';
}
/**
 *
 * @param name
 * reset the expiration date to delete the Cookie
 */
export function deleteCookies(name: string): void {
    document.cookie = name + '=; samesite=strict; secure; Path=/; Expires=Fri, 01 Jan 2021 00:00:01 GMT;';
}
