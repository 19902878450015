import React, { Component } from 'react';
import './TrexInvisibleNode.scss';
import { Handle, Position } from 'react-flow-renderer';

export default class TrexInvisibleNode extends Component {
    render() {
        return (
            <div className='trexInvisibleNode-wrapper'>
                <Handle
                    className='trexInvisibleNode-targetHandle'
                    type='target'
                    position={Position.Left}
                    style={{ cursor: 'default' }}
                />
                <div className='trexInvisible-content' />
                <Handle
                    className='trexInvisibleNode-sourceHandle'
                    type='source'
                    position={Position.Right}
                    style={{ cursor: 'default' }}
                />
            </div>
        );
    }
}
