import React, { Component } from 'react';
import './TrexCheatSheet.scss';
import CheatSheetController from '../../../controller/cheatSheetController';
import { Flavor } from '../../../model/flavor';
import { DevmodeContext } from '../../contexts/Contexts';
import { CheatSheetCategory } from '../../../model/cheatSheetCategory';

// Purpose: Shows references for the most used RegEx tokens/nodes

type CheatSheetProps = {
    flavor: Flavor;
};

type CheatSheetState = {
    currentInformation: string;
};

export default class TrexCheatSheet extends Component<CheatSheetProps, CheatSheetState> {
    static contextType = DevmodeContext;

    renderCheatSheetItems(cheatSheetItems: CheatSheetCategory[]): React.ReactNode[] {
        const resultArray: React.ReactNode[] = [];
        for (const element of cheatSheetItems) {
            resultArray.push(
                <React.Fragment key={element.title}>
                    <div className='trexCheatSheet-categoryTitle'>{element.title}</div>
                    <div className='trexCheatSheet-entryWrapper'>
                        {element.entries.map((entry, id) => (
                            <React.Fragment key={element.title + id}>
                                <code><span className='trexCheatSheet-entryExample'>{entry.symbol}</span></code>
                                <span className='trexCheatSheet-entryDescription'>{entry.description}</span>
                            </React.Fragment>
                        ))}
                    </div>
                </React.Fragment>
            );
        }
        return resultArray;
    }
    render() {
        return (
            <div className='trexCheatSheet-wrapper'>
                {this.renderCheatSheetItems(CheatSheetController.getCheatSheet(this.props.flavor))}
            </div>
        );
    }
}
