import { FormControl } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import './TrexMultiSelectDropdown.scss';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Flag } from '../../../model/flag';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { Flavor } from '../../../model/flavor';

// Purpose: A dropdown that allows to select multiple options to add Flags to the RegEx

type MultiSelectDropdownProps = {
    selectedFlags: Flag[];
    allFlags: Flag[];
    selectedFlavor: Flavor;
    onFlagsChange: (event: ChangeEvent<{ value: unknown }>) => void;
};

export default function TrexMultiSelectDropdown(props: MultiSelectDropdownProps) {
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: '50vh',
                maxWidth: '60vw',
            },
        },
        getContentAnchorEl: null,
    };
    const generateBoldedListItemText = (currentFlag: Flag) => {
        let lastLetter = '';
        const result = [];
        let substring = '';
        let betweenTheStars = false;
        for (const letter of currentFlag.title) {
            if (lastLetter === '*' && letter === '*') {
                if (betweenTheStars) {
                    result.push(<b key={substring}>{substring}</b>);
                } else {
                    result.push(substring);
                }
                substring = '';
                betweenTheStars = !betweenTheStars;
            } else if (letter !== '*') {
                substring += letter;
            }
            lastLetter = letter;
        }
        result.push(substring);
        return result;
    };

    return (
        <FormControl className='TrexMultiSelectDropdown-contain'>
            <Select
                id='flags_chip'
                multiple
                displayEmpty
                value={props.selectedFlags.map((selectedFlag) => selectedFlag.flag)}
                onChange={props.onFlagsChange}
                input={<Input />}
                renderValue={(selected) => {
                    if ((selected as string[]).length === 0) {
                        return (
                            <span>
                                {props.selectedFlavor === Flavor.BRE || props.selectedFlavor === Flavor.ERE
                                    ? 'Options'
                                    : 'Flags'}
                            </span>
                        );
                    }
                    return (selected as string[]).join('');
                }}
                MenuProps={MenuProps}
            >
                {props.allFlags.map((flag) => {
                    return (
                        <MenuItem key={flag.flag} value={flag.flag}>
                            <Checkbox
                                color={'primary'}
                                checked={props.selectedFlags.some((f) => f.flag === flag.flag)}
                            />
                            <ListItemText
                                style={{ whiteSpace: 'normal' }}
                                primary={generateBoldedListItemText(flag)}
                                secondary={flag.description}
                            />
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
