import React from 'react';
import './TrexHeader.scss';
import { DiGit } from 'react-icons/di';
import logo from '../../images/logo.svg';

// Purpose: A header that shows the sites name and a link to the Repository

export default function TrexHeader() {
    return (
        <header className='trexHeader-wrapper'>
            <a href='/' className='trexHeader-title'>
                <svg className='trexHeader-logo' viewBox='0 0 84.33 80.69'>
                    <use href={logo + '#ly-dino'} />
                </svg>
                T-Re(gE)?x
            </a>
            <a
                href='https://gitlab.rlp.net/top/21s/regex-vis/t-regex.git'
                target='_blank'
                className='gitlabIcon-wrapper'
            >
                <DiGit size='1.5em' className='gitlabIcon'></DiGit>
                <span className='trexHeader-gitlab'>Source</span>
            </a>
        </header>
    );
}
