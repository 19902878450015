import { Flavor } from '../model/flavor';
import { RegEx } from '../model/regex';
import { Match } from '../model/match';
import { Flag } from '../model/flag';
import { Controller } from './controller';
import { EngineError, Flag as ParserFlag, Flavors, IFlavor, RegExEngine, TestResult } from '@top/t-regex-parser';
import RootNode from '@top/t-regex-parser/build/src/engine/nodes/rootNode';
import flags from './data/flags.json';

export class ExpressionController extends Controller {
    private _supportedFlavors: Flavor[] = [Flavor.BRE, Flavor.ERE];
    private _cachedMatches: Match[] | undefined;
    private _cachedExpression: RootNode | undefined;
    private _error: boolean | undefined;
    private _errorMessage: EngineError[] = [];

    private static _flagMap: { [flag in ParserFlag]: Flag } = {
        [ParserFlag.NONE]: flags.global,
        [ParserFlag.IGNORECASE]: flags.caseInsensitive,
        [ParserFlag.INVERTMATCH]: flags.invertMatch,
        [ParserFlag.WORDREGEXP]: flags.wordRegexp,
        [ParserFlag.LINEREGEXP]: flags.lineRexexp,

        [ParserFlag.GLOBAL]: flags.global,
        [ParserFlag.MULTILINE]: flags.multiline,
        [ParserFlag.STICKY]: flags.sticky,
        [ParserFlag.SINGLELINE]: flags.singleLine,
        [ParserFlag.UNICODE]: flags.unicode,
    };

    get flavors(): Flavor[] {
        if (this._devMode) return [Flavor.BRE, Flavor.ERE, Flavor.JAVASCRIPT];
        return this._supportedFlavors;
    }

    get cachedMatches(): Match[] | undefined {
        return this._cachedMatches;
    }

    get cachedExpression(): RootNode | undefined {
        return this._cachedExpression;
    }

    get error(): boolean | undefined {
        return this._error;
    }

    get errorMessage(): EngineError[] {
        return this._errorMessage;
    }

    public parseFlagsByString(flagsStr: string, flavor: Flavor): Flag[] {
        const selectedFlags: Flag[] = [];
        if (!flagsStr) return selectedFlags;
        if (flagsStr.trim().length === 0) return selectedFlags;
        for (let i = 0; i < flagsStr.length; i++) {
            selectedFlags.push(...this.getFlags(flavor).filter((value) => value.flag == flagsStr.charAt(i)));
        }
        return selectedFlags;
    }

    public getFlags(chosenFlavor: Flavor): Flag[] {
        // simulate parser behavior if javascript/pcre was implemented
        if (chosenFlavor == Flavor.JAVASCRIPT) {
            return this._devMode ? Object.values(flags) : [];
        }

        const flavor: IFlavor = ExpressionController.convertFlavor(chosenFlavor);
        return flavor.compatibleFlags.map((parserFlag: ParserFlag) =>
            ExpressionController.convertFlagFromParserFlag(parserFlag)
        );
    }

    public parseRegEx(regex: RegEx, useCache: boolean = true): RootNode | undefined {
        if (regex.expression.trim().length === 0 || regex.flavor == Flavor.JAVASCRIPT) {
            delete this._error;
            this._errorMessage = [];
            return undefined;
        }
        if (this.cachedExpression && useCache) return this.cachedExpression;

        const flavor: IFlavor = ExpressionController.convertFlavor(regex.flavor);
        try {
            const engine: RegExEngine = new RegExEngine(
                regex.expression,
                flavor,
                regex.flags.map((entry) => ExpressionController.convertFlagToParserFlag(entry))
            );

            const tree = engine.tree;
            this._cachedExpression = tree;
            this._error = false;
            this._errorMessage = [];

            for (const token of engine.tokens) {
                if (token.error) {
                    this._error = true;
                    this._errorMessage.push(token.error);
                }
            }

            return tree;
        } catch (error) {
            console.log('The parser threw an unexpected error instead of parsing results:', error);
        }

        return undefined;
    }

    public getMatches(regex: RegEx, text: string, useCache: boolean = true): Match[] {
        const matches: Match[] = [];

        if (regex.expression.trim().length === 0) {
            return matches;
        }

        if (this.cachedMatches && useCache) {
            return this.cachedMatches;
        }

        if (regex.flavor == Flavor.JAVASCRIPT) {
            return this.getJavaScriptMatches(regex, text);
        }

        const flavor: IFlavor = ExpressionController.convertFlavor(regex.flavor);

        try {
            const engine: RegExEngine = new RegExEngine(
                regex.expression,
                flavor,
                regex.flags.map((entry) => ExpressionController.convertFlagToParserFlag(entry))
            );

            engine.match(text).forEach((matchFromParser) => {
                matches.push({
                    matchString: matchFromParser.matchText,
                    matchGroups: matchFromParser.groups,
                    matchNodes: [],
                    startPosition: matchFromParser.startPos,
                    endPosition: matchFromParser.endPos,
                    lineNum: matchFromParser.lineNum,
                });
            });
        } catch (error) {
            if (error instanceof EngineError) {
                //console.log(`Error ${error.errorType.toString()} at ${error.errorPosition}: ${error.message}`);
            } else {
                console.log('The parser threw an unexpected error instead of matches:', error);
            }
        }

        this._cachedMatches = matches;
        return matches;
    }

    private getJavaScriptMatches(regex: RegEx, text: string) {
        const matches: Match[] = [];
        try {
            let flag: string = '';
            if (regex.flags) {
                regex.flags.forEach((regflag) => {
                    flag = flag + regflag.flag;
                });
            }

            const fakeRegex = new RegExp(regex.expression, flag);
            const fakeMatches = Array.from(text.matchAll(fakeRegex));

            if (!fakeMatches) {
                this._cachedMatches = matches;
                return matches;
            }

            for (let i = 0; i < fakeMatches.length; i++) {
                const fakeMatchGroups: TestResult[] = [];

                for (let j = 1; j < fakeMatches[i].length; j++) {
                    if (!fakeMatches[i][j]) continue;

                    // startPosition is garbage atm, because we can not get the real start, but need a difference to get a unique key
                    fakeMatchGroups.push({
                        groupNumber: i,
                        endPos: 0,
                        startPos: j,
                        matchText: fakeMatches[i][j],
                        lineNum: 1,
                    });
                }

                // startPosition is garbage atm, because we can not get the real start, but need a difference to get a unique key
                const fakeMatch = {
                    matchString: fakeMatches[i][0],
                    matchNodes: [],
                    matchGroups: fakeMatchGroups,
                    startPosition: i,
                    endPosition: 0,
                    lineNum: 1,
                };
                matches.push(fakeMatch);
            }
        } catch (e) {
            // the expression evaluation can fail => return empty list
            this._cachedMatches = matches;
            return matches;
        }
        this._cachedMatches = matches;
        return matches;
    }

    public clearMatchCache(): void {
        this._cachedMatches = undefined;
    }

    public clearExpressionCache(): void {
        this._cachedExpression = undefined;
    }

    private static convertFlavor(flavor: Flavor): IFlavor {
        if (flavor == Flavor.BRE) {
            return Flavors.bre;
        } else if (flavor == Flavor.ERE) {
            return Flavors.ere;
        }
        return Flavors.bre;
    }

    private static convertFlagFromParserFlag(flag: ParserFlag): Flag {
        if (flag == ParserFlag.NONE) {
            throw new Error('Unexpected flag');
        }
        return this._flagMap[flag];
    }

    private static convertFlagToParserFlag(flag: Flag): ParserFlag {
        return Object.keys(this._flagMap)
            .map(Number)
            .find((key) => this._flagMap[key as ParserFlag] === flag) as ParserFlag;
    }
}
