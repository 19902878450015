import React, { Component } from 'react';
import './TrexEndNode.scss';
import { Handle, Position } from 'react-flow-renderer';

export default class TrexEndNode extends Component {
    render() {
        return (
            <div className='trexEndNode-wrapper'>
                <Handle
                    className='trexEndNode-targethandle'
                    type='target'
                    position={Position.Left}
                    style={{ cursor: 'default' }}
                />
                <div className='trexEndNode-content'>&nbsp;</div>
            </div>
        );
    }
}
