import React, { ChangeEvent, Component } from 'react';
import TrexSideBar from '../components/sidebar/TrexSideBar';
import './TrexParentPage.scss';
import TrexHeader from '../components/misc/TrexHeader';
import TrexMainPage from './TrexMainPage';
import { Flag } from '../../model/flag';
import { Theme, ThemeProvider } from '@material-ui/core/styles';
import { DevmodeContext } from '../contexts/Contexts';
import { Flavor } from '../../model/flavor';
import { match, withRouter } from 'react-router';
import { History, Location } from 'history';
import { ExpressionController } from '../../controller/expressionController';
import { ThemeController } from '../../controller/themeController';
import HighlightingController from '../../controller/highlightingController';
import { RegEx } from '../../model/regex';
import { getCookies, setCookies } from '../../controller/cookies';

type TrexParentPageProps = {
    match: match;
    location: Location;
    history: History;
};

type TrexParentPageState = {
    opened: boolean;
    regex: RegEx;
    inputText: string;
    flavor: Flavor;
    flags: Flag[];
    devMode: boolean;
    expressionController: ExpressionController;
    themeController: ThemeController;
    muiTheme: Theme;
    autoFitDiagram: boolean;
    highlightingController: HighlightingController;
    regexOrInputChangedOnce: boolean;
};

class TrexParentPageWithoutRouter extends Component<TrexParentPageProps, TrexParentPageState> {
    constructor(props: TrexParentPageProps) {
        super(props);
        const urlParams = new URLSearchParams(props.location.search);
        const devBranch = this.isDeveloperBranch();
        const themeController = new ThemeController(devBranch, 0);
        const expressionController = new ExpressionController(devBranch);
        const flavor: Flavor = ((urlParams.get('flav') as unknown) as Flavor) || Flavor.ERE;
        const regexStr = urlParams.get('r') || '(T-Re)([gG][eE])?x';
        const inputText = urlParams.get('t') || '👀 Insert your T-RegEx-Text to summon a T-Rex 👀';
        const selectedFlags = expressionController.parseFlagsByString(urlParams.get('flag') as string, flavor) || [];

        this.state = {
            opened: false,
            regex: {
                expression: regexStr,
                flags: selectedFlags,
                flavor: flavor,
            },
            inputText: inputText,
            flavor: flavor,
            flags: expressionController.getFlags(flavor),
            devMode: devBranch,
            expressionController: expressionController,
            themeController: themeController,
            muiTheme: themeController.getMuiTheme(),
            autoFitDiagram: getCookies('autoFitDiagram') !== '' ? getCookies('autoFitDiagram') === 'true' : true,
            highlightingController: new HighlightingController(devBranch),
            regexOrInputChangedOnce: false,
        };
        props.history.replace(window.location.pathname);
    }

    onOpenChange = (opened: boolean) => {
        this.setState({ opened });
    };

    setSelectedFlags(flagId: string[], callback: () => void) {
        const selectedFlags: Flag[] = [];
        flagId.forEach((flagId) => {
            const searchFlag: Flag | undefined = this.state.flags.find((flag) => flag.flag === flagId);
            if (searchFlag) selectedFlags.push(searchFlag);
        });
        this.setState(
            (state) => ({
                regex: {
                    flavor: state.regex.flavor,
                    flags: selectedFlags,
                    expression: state.regex.expression,
                },
            }),
            () => {
                callback();
            }
        );
    }

    isDeveloperBranch(): boolean {
        return !(window.location.pathname.startsWith('/main') || window.location.pathname === '/');
    }

    updateHighlighting = () => {
        this.state.highlightingController.updateMatchHighlighting(
            this.state.expressionController.getMatches(this.state.regex, this.state.inputText)
        );
        this.state.highlightingController.updateRegExHighlighting(
            this.state.expressionController.parseRegEx(this.state.regex),
            this.state.expressionController.errorMessage,
            this.state.regex.flavor
        );
    };

    onChangeRegex = (newValue: string) => {
        this.setState(
            (state) => ({
                regex: {
                    expression: newValue,
                    flavor: state.regex.flavor,
                    flags: state.regex.flags,
                },
                regexOrInputChangedOnce: true,
            }),
            () => {
                this.updateHighlighting();
            }
        );
    };

    onUnload = (e: BeforeUnloadEvent) => {
        if (this.state.regexOrInputChangedOnce) {
            e.preventDefault();
            e.returnValue = '';
        }
    };

    componentDidMount() {
        window.addEventListener('beforeunload', this.onUnload);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onUnload);
    }

    render() {
        return (
            <div id='themeProvider' className={'theme-' + this.state.themeController.getThemeName()}>
                <div id='themedBody'>
                    <ThemeProvider theme={this.state.muiTheme}>
                        <DevmodeContext.Provider
                            value={{
                                devMode: this.state.devMode,
                                switchDevmode: () => {
                                    const newHighlightingController = new HighlightingController(!this.state.devMode);
                                    newHighlightingController.regexEditor = this.state.highlightingController.regexEditor;
                                    newHighlightingController.regexEditorModal = this.state.highlightingController.regexEditorModal;
                                    newHighlightingController.matchTextEditor = this.state.highlightingController.matchTextEditor;
                                    const regex = this.state.regex;
                                    regex.flavor = Flavor.ERE;
                                    regex.flags = [];
                                    this.setState(
                                        (state) => ({
                                            devMode: !state.devMode,
                                            expressionController: new ExpressionController(!state.devMode),
                                            highlightingController: newHighlightingController,
                                            flavor: Flavor.ERE,
                                            flags: state.expressionController.getFlags(state.flavor),
                                            regex,
                                        }),
                                        () => {
                                            this.updateHighlighting();
                                        }
                                    );
                                },
                            }}
                        >
                            <TrexSideBar
                                opened={this.state.opened}
                                onOpenChange={this.onOpenChange}
                                regEx={this.state.regex}
                                text={this.state.inputText}
                                onSlide={(eventData) => {
                                    if (this.state.opened && eventData.dir === 'Left') this.onOpenChange(false);
                                    else if (!this.state.opened && eventData.dir === 'Right') this.onOpenChange(true);
                                }}
                                themeController={this.state.themeController}
                                onChangeTheme={() =>
                                    this.setState({
                                        muiTheme: this.state.themeController.getMuiTheme(),
                                    })
                                }
                                onChangeAutoFitDiagram={(value: boolean) => {
                                    setCookies('autoFitDiagram', String(value));
                                    this.setState({
                                        autoFitDiagram: value,
                                    });
                                }}
                                defaultAutoFitDiagram={this.state.autoFitDiagram}
                            />
                            <div
                                className={
                                    this.state.opened
                                        ? 'trexParentPage-content trexParentPage-content-extended'
                                        : 'trexParentPage-content trexParentPage-content-collapsed'
                                }
                            >
                                <TrexHeader />

                                <TrexMainPage
                                    regex={this.state.regex}
                                    allFlags={this.state.expressionController.getFlags(this.state.flavor)}
                                    selectedFlags={this.state.regex.flags}
                                    selectedFlavor={this.state.flavor}
                                    inputText={this.state.inputText}
                                    expressionController={this.state.expressionController}
                                    autoFitDiagram={this.state.autoFitDiagram}
                                    highlightingController={this.state.highlightingController}
                                    onChangeRegex={this.onChangeRegex}
                                    onChangeInputText={(newValue: string) => {
                                        this.setState({ inputText: newValue, regexOrInputChangedOnce: true }, () => {
                                            this.updateHighlighting();
                                        });
                                    }}
                                    onChangeFlavor={(
                                        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
                                        child: React.ReactNode,
                                        callback: () => void
                                    ) => {
                                        const newFlavor: number = event.target.value as number;

                                        this.setState(
                                            (state) => ({
                                                flavor: newFlavor,
                                                flags: this.state.expressionController.getFlags(newFlavor),
                                                regex: {
                                                    flags: [],
                                                    flavor: newFlavor,
                                                    expression: state.regex.expression,
                                                },
                                            }),
                                            () => {
                                                this.updateHighlighting();
                                                callback();
                                            }
                                        );
                                    }}
                                    onFlagsChange={(event: ChangeEvent<{ value: unknown }>) => {
                                        this.setSelectedFlags(event.target.value as string[], this.updateHighlighting);
                                    }}
                                    // This should load the matches (not use the cache)
                                    matches={this.state.expressionController.getMatches(
                                        this.state.regex,
                                        this.state.inputText,
                                        false
                                    )}
                                />
                            </div>
                        </DevmodeContext.Provider>
                    </ThemeProvider>
                </div>
            </div>
        );
    }
}

const TrexParentPage = withRouter(TrexParentPageWithoutRouter);
export default TrexParentPage;
