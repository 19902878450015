import React, { Component } from 'react';
import './TrexDetails.scss';
import TrexDiagramOutput from './TrexDiagramOutput';
import { RegEx } from '../../../model/regex';
import { ExpressionController } from '../../../controller/expressionController';
import TrexDropdown from '../misc/TrexDropdown';
import { DevmodeContext } from '../../contexts/Contexts';
import DiagramController from '../../../controller/diagramController';
import TrexIconButton from '../misc/TrexIconButton';
import TrexDiagramOutputModal from './TrexDiagramOutputModal';
import HighlightingController from '../../../controller/highlightingController';
import { Flag } from '../../../model/flag';
import { Flavor } from '../../../model/flavor';

// Purpose: a wrapper for the tree and diagram that switches the selected component

type TrexDetailsProps = {
    onChangeRegex: (newValue: string) => void;
    regEx: RegEx;
    expressionController: ExpressionController;
    highlightingController: HighlightingController;
    selectedFlags: Flag[];
    selectedFlavor: Flavor;
    autoFitDiagram: boolean;
    inputText: string;
};

type TrexDetailsState = {
    selectedDummyData: number;
    diagramModalOpened: boolean;
};

export default class TrexDetails extends Component<TrexDetailsProps, TrexDetailsState> {
    static contextType = DevmodeContext;

    constructor(props: TrexDetailsProps) {
        super(props);
        this.state = { selectedDummyData: 0, diagramModalOpened: false };
    }

    changedModal = () => {
        const editor = this.props.highlightingController.regexEditorModal;
        if (editor) {
            const cursorPos = editor.getCursor();
            editor.getDoc().setValue(this.props.regEx.expression);
            editor.setCursor(cursorPos);
            this.props.highlightingController.updateRegExHighlighting(
                this.props.expressionController.parseRegEx(this.props.regEx),
                this.props.expressionController.errorMessage,
                this.props.regEx.flavor
            );
        }
        this.setState({ diagramModalOpened: !this.state.diagramModalOpened });
    };

    render() {
        return (
            <div className='trexDetails-wrapper'>
                <div className='trexDetails-header'>
                    Diagram
                    {this.context.devMode && (
                        <TrexDropdown
                            id='trexDetails-dummyDataSelection'
                            selectedOption={this.state.selectedDummyData}
                            onChange={(event, value) => {
                                this.setState({ selectedDummyData: event.target.value as number });
                            }}
                            values={DiagramController.dummyData}
                        />
                    )}
                    <TrexIconButton
                        onClick={this.changedModal}
                        icon='FiMaximize2'
                        fullScreenButton
                        size={26}
                        name='Fullscreen'
                    />
                </div>

                <TrexDiagramOutputModal
                    autoFit={this.props.autoFitDiagram}
                    onChangeRegex={this.props.onChangeRegex}
                    selectedFlavor={this.props.selectedFlavor}
                    selectedFlags={this.props.selectedFlags}
                    highlightingController={this.props.highlightingController}
                    regEx={this.props.regEx}
                    expressionController={this.props.expressionController}
                    mockData={this.state.selectedDummyData}
                    onClose={this.changedModal}
                    open={this.state.diagramModalOpened}
                />
                {this.props.regEx.expression === 'summon T-Rex' || this.props.inputText === 'summon T-Rex' ? (
                    <iframe
                        src='https://wayou.github.io/t-rex-runner/'
                        frameBorder='0'
                        scrolling='no'
                        width='100%'
                        height='100%'
                        loading='lazy'
                    />
                ) : (
                    <TrexDiagramOutput
                        regEx={this.props.regEx}
                        expressionController={this.props.expressionController}
                        autoFit={this.props.autoFitDiagram}
                        mockData={this.state.selectedDummyData}
                    />
                )}
            </div>
        );
    }
}
