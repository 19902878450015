// Purpose: gives the needet data for the cheatsheet depending on the selected flavor.

import { Controller } from './controller';
import helpModalContent from './data/helpModalContent.json';
import { HelpModalFlavor } from '../model/helpModelFlavor';

export default class HelpModalController extends Controller {
    static getHelpTexts(): HelpModalFlavor[] {
        return helpModalContent;
    }
}
