// Purpose: gives the needet data for the cheatsheet depending on the selected flavor.

import { CheatSheetCategory } from '../model/cheatSheetCategory';
import { Flavor } from '../model/flavor';
import { Controller } from './controller';
import cheatSheetContent from './data/cheatSheetContent.json';

export default class CheatSheetController extends Controller {
    public static getCheatSheet(flavor : Flavor): CheatSheetCategory[] {
       return cheatSheetContent[flavor];
    }
}
