import React, { Component } from 'react';
import './TrexAnchor.scss';
import { Handle, Position } from 'react-flow-renderer';

type TrexAnchorProps = {
    data: {
        label: string;
    };
};

export default class TrexAnchor extends Component<TrexAnchorProps> {
    render() {
        return (
            <div className='trexAnchor-wrapper'>
                <Handle
                    className='trexAnchor-targetHandle'
                    type='target'
                    position={Position.Left}
                    style={{ cursor: 'default' }}
                />
                <div className='trexAnchor-content'>{this.props.data.label}</div>
                <Handle
                    className='trexAnchor-sourcehandle'
                    type='source'
                    position={Position.Right}
                    style={{ cursor: 'default' }}
                />
            </div>
        );
    }
}
