import React from 'react';
import './TrexMatchOutput.scss';
import { Match } from '../../../model/match';
import ExpressionUtils from '../../../controller/utils/expressionUtils';
import { randomInt } from 'crypto';

// Purpose: Shows the matches found in the text with the RegEx input.

type MatchOutputProps = {
    matches: Match[];
};

export default function TrexMatchOutput(props: MatchOutputProps) {
    return (
        <div className='trexMatchOutput-wrapper'>
            <div className='trexMatchOutput-header'>
                {props.matches.length + (props.matches.length === 1 ? ' match' : ' matches')}
            </div>
            <div className='trexMatchOutput-content'>
                <ul>
                    {props.matches.map((match, i) => {
                        if (match.matchGroups?.length === 0) {
                            return (
                                <div
                                    className='trexMatchOutput-match'
                                    key={`${match.startPosition}-${match.endPosition}#${i}`}
                                >
                                    <div className='trexMatchOutput-matchbar' />
                                    <div className='trexMatchOutput-matchcontent'>
                                        <li>
                                            <span className='trexMatchOutput-matchnumber'>{'Match ' + (i + 1)}</span>
                                            <span className='trexMatchOutput-matchposition'>
                                                {`(ln: ${match.lineNum}, col: ${match.startPosition}-${match.endPosition})`}
                                            </span>
                                            <span className='trexMatchOutput-matchtext'>{match.matchString}</span>
                                        </li>
                                    </div>
                                </div>
                            );
                        }
                        return (
                            <React.Fragment key={`${match.startPosition}-${match.endPosition}#${i}`}>
                                <div className='trexMatchOutput-match'>
                                    <div className='trexMatchOutput-matchbar' />
                                    <div className='trexMatchOutput-matchcontent'>
                                        <li>
                                            <span className='trexMatchOutput-matchnumber'>{'Match ' + (i + 1)}</span>
                                            <span className='trexMatchOutput-matchposition'>
                                                {`(ln: ${match.lineNum}, col: ${match.startPosition}-${match.endPosition})`}
                                            </span>
                                            <span className='trexMatchOutput-matchtext'>{match.matchString}</span>
                                        </li>
                                        <ul>
                                            {match.matchGroups?.map((group) => {
                                                return (
                                                    <li
                                                        key={`g-${match.startPosition}-${match.endPosition}-
                                                        ${group.startPos}#${group.groupNumber}`}
                                                    >
                                                        <span className='trexMatchOutput-groupname'>
                                                            {ExpressionUtils.getMatchGroupName(
                                                                group.groupName,
                                                                group.groupNumber
                                                            )}
                                                        </span>
                                                        <span className='trexMatchOutput-groupposition'>
                                                            {`(ln: ${group.lineNum}, col: ${group.startPos}-${group.endPos})`}
                                                        </span>
                                                        <span className='trexMatchOutput-grouptext'>
                                                            {group.matchText}
                                                        </span>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}
