import { Controller } from './controller';
import { ChangeEvent } from 'react';
import { createMuiTheme, Theme } from '@material-ui/core/styles';
import variables from '../view/scssVariables/variables.module.scss';
import { PaletteType } from '@material-ui/core';
import { getCookies, setCookies } from './cookies';

export const AVAILABLE_LIGHT_THEMES = [
    'lightTwitch',
    'lightFrog',
    'lightTelekom',
    'lightMclaren',
    'lightWine',
    'frohesSchaffen',
];

export const AVAILABLE_DARK_THEMES = [
    'darkTwitch',
    'darkFrog',
    'darkTelekom',
    'darkMclaren',
    'darkWine',
    'frohesSchaffen',
];

export const THEME_NAMES = ['Purple', 'Green', 'Magenta', 'Orange', 'Red', 'Frohes Schaffen'];

export class ThemeController extends Controller {
    private _theme: number = 0;
    private _themeMode: boolean = false;

    constructor(devMode: boolean, defaultTheme: number) {
        super(devMode);
        const themeCookie = getCookies('theme');
        if (themeCookie) {
            this._theme = Number(themeCookie);
        } else {
            this._theme = defaultTheme;
        }
        const themeModeCookie = getCookies('themeMode');
        if (themeModeCookie == 'true') {
            this._themeMode = true;
        }
    }

    get theme(): number {
        return this._theme;
    }

    set theme(theme: number) {
        this._theme = theme;
    }

    get themeMode(): boolean {
        return this._themeMode;
    }

    set themeMode(themeMode: boolean) {
        this._themeMode = themeMode;
    }

    changeTheme = (themeIndex: number, mode: boolean): void => {
        setCookies('theme', String(themeIndex));
        setCookies('themeMode', String(mode));
        this.theme = themeIndex;
        this.themeMode = mode;
    };

    getThemeName() {
        let themeName = AVAILABLE_LIGHT_THEMES[this.theme];
        if (this.themeMode) {
            themeName = AVAILABLE_DARK_THEMES[this.theme];
        }
        return themeName;
    }

    getMuiTheme(): Theme {
        const themeName = this.getThemeName();
        return createMuiTheme({
            palette: {
                type: variables[themeName + '-theme'] as PaletteType,
                primary: {
                    light: variables[themeName + '-primaryColorLight'],
                    main: variables[themeName + '-primaryColorDark'],
                    dark: variables[themeName + '-primaryColorDark'],
                    contrastText: variables[themeName + '-textColorOnPrimary'],
                },
                secondary: {
                    light: variables[themeName + '-secondaryColorLight'],
                    main: variables[themeName + '-secondaryColor'],
                    dark: variables[themeName + '-secondaryColorDark'],
                    contrastText: variables[themeName + '-textColorOnSecondary'],
                },
            },
        });
    }
}
