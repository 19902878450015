import React, { Component } from 'react';
import './TrexStartNode.scss';
import { Handle, Position } from 'react-flow-renderer';

export default class TrexStartNode extends Component {
    render() {
        return (
            <div className='trexStartNode-wrapper'>
                <Handle
                    className='trexStartNode-sourcehandle'
                    type='source'
                    position={Position.Right}
                    style={{ cursor: 'default' }}
                />
                <div className='trexStartNode-content'>&nbsp;</div>
            </div>
        );
    }
}
