import React, { Component } from 'react';
import './TrexCharacterClass.scss';
import { Handle, Position } from 'react-flow-renderer';

type TrexCharacterClassProps = {
    data: {
        label: string;
        negation: boolean;
    };
};

export default class TrexCharacterClass extends Component<TrexCharacterClassProps> {
    static getMinWidth(negation: boolean): number {
        return negation ? 142 : 126;
    }

    render() {
        return (
            <fieldset className='trexCharacterClass-wrapper'>
                <legend className='trexCharacterClass-legend' hidden={!this.props.data.negation}>
                    None of
                </legend>
                <legend className='trexCharacterClass-legend' hidden={this.props.data.negation}>
                    One of
                </legend>
                <Handle
                    className='trexCharacterClass-handle'
                    type='target'
                    position={Position.Left}
                    style={{ cursor: 'default' }}
                />
                <div className='trexCharacterClass-content'>{this.props.data.label}</div>
                <Handle
                    className='trexCharacterClass-handle'
                    type='source'
                    position={Position.Right}
                    style={{ cursor: 'default' }}
                />
            </fieldset>
        );
    }
}
