import { GroupExpressionNode, Node as TrexNode } from '@top/t-regex-parser';
import { TrexMockNode } from '../diagramController';

export default class ExpressionUtils {
    public static getGroupNumber(currentNode: TrexNode | TrexMockNode): number {
        if (currentNode instanceof GroupExpressionNode) {
            return currentNode.groupNumber;
        }
        return 0;
    }

    public static getMatchGroupNameByNode(currentNode: TrexNode | TrexMockNode): string {
        if (currentNode instanceof GroupExpressionNode) {
            return this.getMatchGroupName(currentNode.groupName, currentNode.groupNumber);
        }
        return this.getMatchGroupName(undefined, 0);
    }

    public static getMatchGroupName(groupName: string | undefined, groupNumber: number | undefined): string {
        return `Group ${groupName || ((groupNumber || 0) + 1).toString()}`;
    }
}
