import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import React, { Component } from 'react';
import { ExpressionController } from '../../../controller/expressionController';
import { RegEx } from '../../../model/regex';
import TrexIconButton from '../misc/TrexIconButton';
import TrexDiagramOutput from './TrexDiagramOutput';
import CodeMirror from 'react-codemirror';
import HighlightingController from '../../../controller/highlightingController';
import { Flavor } from '../../../model/flavor';
import { Flag } from '../../../model/flag';
import './TrexDiagramOutputModal.scss';

type TrexDiagramOutputModalProps = {
    open: boolean;
    onChangeRegex: (newValue: string) => void;
    onClose: (event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => void;
    regEx: RegEx;
    expressionController: ExpressionController;
    mockData?: number;
    highlightingController: HighlightingController;
    selectedFlavor: Flavor;
    selectedFlags: Flag[];
    autoFit: boolean;
};

export default class TrexDiagramOutputModal extends Component<TrexDiagramOutputModalProps> {
    setCodemirrorEditor() {
        this.props.highlightingController.regexEditorModal = (this.refs
            .editor as ReactCodeMirror.ReactCodeMirror).getCodeMirror();

        this.props.highlightingController.updateRegExHighlighting(
            this.props.expressionController.parseRegEx({
                expression: this.props.regEx.expression,
                flavor: this.props.selectedFlavor,
                flags: this.props.selectedFlags,
            }),
            this.props.expressionController.errorMessage,
            this.props.regEx.flavor
        );
    }

    render() {
        return (
            <Modal
                onRendered={() => {
                    this.setCodemirrorEditor();
                }}
                keepMounted
                className='TrexDiagramOutputModal-modal'
                open={this.props.open}
                onClose={this.props.onClose}
                container={document.getElementById('themeProvider')}
            >
                <Fade in={this.props.open}>
                    <div className='TrexDiagramOutputModal-wrapper'>
                        <div className='TrexDiagramOutputModal-titlebar'>
                            <CodeMirror
                                value={this.props.regEx.expression}
                                ref='editor'
                                className='trexRegExInput-inputText'
                                options={{
                                    readOnly: true,
                                    lineNumbers: false,
                                    autofocus: true,
                                    scrollbarStyle: 'null',
                                }}
                            />
                            <TrexIconButton
                                icon='FiX'
                                modal
                                onClick={() => {
                                    this.props.onClose({}, 'backdropClick');
                                }}
                            />
                        </div>
                        <TrexDiagramOutput
                            autoFit={this.props.autoFit}
                            regEx={this.props.regEx}
                            expressionController={this.props.expressionController}
                            mockData={this.props.mockData}
                        />
                    </div>
                </Fade>
            </Modal>
        );
    }
}
