import React, { Component } from 'react';
import './TrexSettings.scss';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { DevmodeContext } from '../../contexts/Contexts';
import { THEME_NAMES, ThemeController } from '../../../controller/themeController';
import TrexDropdown from '../misc/TrexDropdown';
import { deleteCookies, getCookies, setCookies } from '../../../controller/cookies';

// Purpose: Change TrexSettings, such as design (dark, light, color) etc.

// TrexSettings will be saven in Context

type TrexSettingsProps = {
    themeController: ThemeController;
    onChangeTheme: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
    onChangeAutoFitDiagram: (value: boolean) => void;
    defaultAutoFitDiagram: boolean;
};

type TrexSettingsState = {
    currentTheme: number;
    currentThemeMode: boolean;
    cookies: boolean;
    autoFitDiagram: boolean;
};

export default class TrexSettings extends Component<TrexSettingsProps, TrexSettingsState> {
    static contextType = DevmodeContext;

    constructor(props: TrexSettingsProps) {
        super(props);
        this.state = {
            currentTheme: props.themeController.theme,
            currentThemeMode: props.themeController.themeMode,
            autoFitDiagram: props.defaultAutoFitDiagram,
            cookies: getCookies('cookiesApproved') === 'true',
        };
    }
    cookiesSet(cookies: boolean): void {
        this.setState({ cookies: cookies });
        if (cookies) {
            setCookies('cookiesApproved', String(cookies));
            setCookies('theme', String(this.state.currentTheme));
            setCookies('themeMode', String(this.state.currentThemeMode));
            setCookies('autoFitDiagram', String(this.state.autoFitDiagram));
        } else {
            deleteCookies('theme');
            deleteCookies('themeMode');
            deleteCookies('cookiesApproved');
            deleteCookies('autoFitDiagram');
        }
    }

    getDevelopmentUrl(): string {
        const currUrl = window.location.href;
        return currUrl.substr(0, currUrl.length - window.location.pathname.length) + '/development';
    }

    renderDevModeSwitch = () => {
        return (
            <div className='trexSettings-row-devMode'>
                <div className='trexSettings-row-devModeGrid'>
                    <span className='trexSettings-label'>Dev-Mode</span>
                    <Switch
                        checked={this.context.devMode}
                        onChange={this.context.switchDevmode}
                        color='primary'
                        name='devMode'
                        id={'devModeSwitch'}
                    />
                </div>
            </div>
        );
    };

    renderThemeSwitcher = () => {
        return (
            <>
                <div className='trexSettings-row'>
                    <span className='trexSettings-label'>Theme</span>
                    <TrexDropdown
                        id='theme'
                        selectedOption={this.state.currentTheme}
                        onChange={(event) => {
                            this.setState({ currentTheme: event.target.value as number });
                            this.props.themeController.changeTheme(
                                event.target.value as number,
                                this.state.currentThemeMode
                            );
                            this.props.onChangeTheme(event);
                        }}
                        values={THEME_NAMES}
                    />
                </div>
                <div className='trexSettings-row'>
                    <span className='trexSettings-label'>Light/Dark</span>
                    <Switch
                        className='trexSettings-Switch'
                        checked={this.state.currentThemeMode}
                        onChange={(event) => {
                            this.setState({ currentThemeMode: event.target.checked });
                            this.props.themeController.changeTheme(this.state.currentTheme, event.target.checked);
                            this.props.onChangeTheme(event);
                        }}
                        color='primary'
                        name='switchThemeMode'
                        id={'switchThemeMode'}
                    />
                </div>
            </>
        );
    };

    renderAutoFitDiagramSwitch = () => {
        return (
            <div className='trexSettings-row'>
                <span className='trexSettings-label'>Auto-fit diagram</span>
                <Switch
                    checked={this.state.autoFitDiagram}
                    onChange={(event) => {
                        this.setState({ autoFitDiagram: event.target.checked });
                        this.props.onChangeAutoFitDiagram(event.target.checked);
                    }}
                    color='primary'
                    name='autoFitDiagram'
                    id={'autoFitDiagramSwitch'}
                />
            </div>
        );
    };

    renderCookieSwitcher = () => {
        return (
            <>
                <div className='trexSettings-row'>
                    <span className='trexSettings-label'>Save Settings</span>
                    <Switch
                        checked={this.state.cookies}
                        onChange={(event) => {
                            this.cookiesSet(event.target.checked);
                        }}
                        color='primary'
                        name='cookies'
                        id={'cookiesSwitch'}
                    />
                </div>
                <text className='TrexSettings-cookiesText'>
                    {this.state.cookies
                        ? 'Your settings will be saved in cookies'
                        : 'Enable to save your settings in cookies'}
                </text>
            </>
        );
    };

    render() {
        return (
            <div className='trexSettings-wrapper'>
                {window.location.pathname.startsWith('/main') || window.location.pathname === '/' ? (
                    <a className='trexSettings-devLink' href={this.getDevelopmentUrl()}>
                        Go to development version
                    </a>
                ) : (
                    this.renderDevModeSwitch()
                )}
                <div className='trexSettings-settingsGrid'>
                    {this.renderCookieSwitcher()}
                    <hr />
                    {this.renderThemeSwitcher()}
                    <hr />
                    {this.renderAutoFitDiagramSwitch()}
                </div>
            </div>
        );
    }
}
