import React, { ReactChild, ReactElement } from 'react';
import './TrexFlavorModal.scss';
import { Modal } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { FiExternalLink } from 'react-icons/fi';
import TrexIconButton from '../misc/TrexIconButton';
import HelpModalController from '../../../controller/helpModalController';
import { Flavor } from '../../../model/flavor';

type TrexFlavorModalProps = {
    children?: ReactChild;
    open: boolean;
    onClose: (event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => void;
    selectedFlavor: Flavor;
};

export default function TrexFlavorModal(props: TrexFlavorModalProps) {
    return (
        <div>
            <Modal
                className='trexFlavorModal-modal'
                open={props.open}
                onClose={props.onClose}
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                container={document.getElementById('themeProvider')}
            >
                <Fade in={props.open}>
                    <div className='trexFlavorModal-wrapper'>
                        <div className='trexFlavorModal-titlebar'>
                            <h2 id='transition-modal-title'>Flavor help</h2>
                            <TrexIconButton
                                icon='FiX'
                                modal
                                onClick={() => {
                                    props.onClose({}, 'backdropClick');
                                }}
                            />
                        </div>
                        <div id='transition-modal-description'>
                            {HelpModalController.getHelpTexts().map((flavor) => {
                                return (
                                    <div
                                        key={flavor.name}
                                        className={`trexFlavorModal-flavor-wrapper ${
                                            'supported' in flavor
                                                ? flavor.supported
                                                    ? 'supported'
                                                    : 'notSupported'
                                                : ''
                                        } ${flavor.key === props.selectedFlavor ? 'selected' : ''}`}
                                    >
                                        <span className='trexFlavorModal-flavor-title'>
                                            {flavor.name}
                                            <a href={flavor.link} target='_blank'>
                                                <FiExternalLink
                                                    className='trexFlavorModal-ref-icon'
                                                    size='15'
                                                ></FiExternalLink>
                                            </a>
                                        </span>

                                        <div className='trexFlavorModal-flavor-categories'>
                                            {flavor.content.map((category) => {
                                                const output: ReactElement[] = [];
                                                if ('title' in category) {
                                                    output.push(
                                                        <div
                                                            className='trexFlavorModal-flavor-catTitle'
                                                            key={category.title}
                                                        >
                                                            {category.title}
                                                        </div>
                                                    );
                                                }
                                                output.push(
                                                    <span
                                                        className='trexFlavorModal-flavor-catContent'
                                                        key={flavor.name}
                                                        dangerouslySetInnerHTML={{ __html: category.content }}
                                                    />
                                                );
                                                return output;
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='trexFlavorModal-learnMore-link'>
                            <a
                                href='https://gitlab.rlp.net/groups/top/21s/regex-vis/-/wikis/RegEx-engine-comparison'
                                target='_blank'
                            >
                                Learn more
                                <FiExternalLink className='trexFlavorModal-learn-icon' size='18' />
                            </a>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
