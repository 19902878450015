import React, { useState } from 'react';
import TrexIconButton from '../misc/TrexIconButton';
import './TrexShare.scss';
import Tooltip from '@material-ui/core/Tooltip';
import * as Feather from 'react-icons/fi';
import { RegEx } from '../../../model/regex';

// Purpose: Get a link to share the RegEx in Input and text

type ShareProps = {
    regEx: RegEx;
    text: string;
};

export default function TrexShare(props: ShareProps) {
    const [copied, setCopied] = useState(false);
    const [tooltipTitle, setTooltipTitle] = useState('Copied');
    const [openedLink, setOpenedLink] = useState(false);

    const flags = props.regEx.flags.map((f) => encodeURIComponent(f.flag)).join('');

    const path = `${window.location.href}?r=${encodeURIComponent(props.regEx.expression)}&t=${encodeURIComponent(
        props.text
    )}&flag=${flags}&flav=${encodeURIComponent(props.regEx.flavor)}`;

    return (
        <div className='TrexShare-wrapper'>
            <span className='TrexShare-shareMessage'>
                {path.length <= 4000
                    ? 'Share your Expressions with your friends'
                    : 'We are sorry, but the link is too long'}
            </span>
            {path.length <= 4000 ? (
                <>
                    <div className={openedLink ? 'TrexShare-linkTextOpened' : 'TrexShare-linkText'}>{path}</div>
                    <div className='TrexShare-openLinkwrapper' onClick={() => setOpenedLink(!openedLink)}>
                        {React.createElement(openedLink ? Feather.FiChevronUp : Feather.FiChevronDown, {
                            size: 35,
                            className: 'TrexShare-Icon',
                        })}
                    </div>
                    <Tooltip
                        open={copied}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={tooltipTitle}
                        arrow
                    >
                        <div className='TrexShare-IconButtonWrapper'>
                            <TrexIconButton
                                size={20}
                                icon='FiCopy'
                                title='Copy'
                                onClick={() => {
                                    if (navigator.clipboard) {
                                        navigator.clipboard.writeText(path);
                                        setTooltipTitle('Copied!');
                                        setCopied(true);
                                        const interval = setInterval(() => {
                                            setCopied(false);
                                            clearInterval(interval);
                                        }, 4000);
                                    } else {
                                        setTooltipTitle(
                                            'Due to browser restriction this is impossible. Try copy via "crtl + c"'
                                        );
                                        setCopied(true);
                                        const interval = setInterval(() => {
                                            setCopied(false);
                                            clearInterval(interval);
                                        }, 10000);
                                    }
                                }}
                            />
                        </div>
                    </Tooltip>
                </>
            ) : null}
        </div>
    );
}
