import { Controller } from './controller';
import { Elements, Node, XYPosition } from 'react-flow-renderer';
import NodeType from '@top/t-regex-parser/build/src/engine/nodes/nodeType';
import dummyDataCharacterClass from './data/diagram/dummyDataCharacterClass.json';
import dummyDataSingleStateMerging from './data/diagram/dummyDataSingleStateMerging.json';
import dummyDataGroups4 from './data/diagram/dummyDataGroups4.json';
import dummyDataGroups3 from './data/diagram/dummyDataGroups3.json';
import dummyDataGroups2 from './data/diagram/dummyDataGroups2.json';
import dummyDataGroups1 from './data/diagram/dummyDataGroups1.json';
import dummyDataAlternation1 from './data/diagram/dummyDataAlternation1.json';
import dummyDataAlternation2 from './data/diagram/dummyDataAlternation2.json';
import dummyDataAlternation3 from './data/diagram/dummyDataAlternation3.json';
import dummyDataAlternation4 from './data/diagram/dummyDataAlternation4.json';
import dummyDataAlternation5 from './data/diagram/dummyDataAlternation5.json';
import dummyDataAlternation6 from './data/diagram/dummyDataAlternation6.json';
import dummyDataRepetition1 from './data/diagram/dummyDataRepetition1.json';
import dummyDataRepetition2 from './data/diagram/dummyDataRepetition2.json';
import dummyDataRepetition3 from './data/diagram/dummyDataRepetition3.json';
import dummyDataRepetition4 from './data/diagram/dummyDataRepetition4.json';
import dummyDataRepetition5 from './data/diagram/dummyDataRepetition5.json';
import dummyDataRepetition6 from './data/diagram/dummyDataRepetition6.json';
import { AnchorExpressionNode, Node as TrexNode, RepetitionExpressionNode, RootNode } from '@top/t-regex-parser';
import ExpressionUtils from './utils/expressionUtils';
import TrexGroup from '../view/components/react-flow/nodes/TrexGroup';
import TrexCharacterClass from '../view/components/react-flow/nodes/TrexCharacterClass';

export type TrexMockNode = {
    type: number;
    content: string;
    position: number;
    // eslint-disable-next-line
    range: { _range: { from: string; to: string }[] };
    length: number;
    minLength: number;
    startAnchor?: boolean;
    endAnchor?: boolean;
    children: TrexMockNode[];
    minRepetition?: number;
    maxRepetition?: number;
};

type DiagramData = {
    elementArray: Node[];
    nodeTree: TrexNode[] | TrexMockNode[];
    currentIndex: number;
    lastPosition: XYPosition;
    connectorHandlings: ConnectorHandling[];
    repetitionConnectors: RepetitionConnector[];
    negation?: boolean;
    currentAlternationSource?: number;
    alternationSource?: number;
};

type ConnectorHandling = {
    sourceNodes: number[];
    targetNodes: number[];
    targetConnector?: string;
    sourceConnector?: string;
    xOffset?: number[];
};

type RepetitionConnector = {
    sourceNode: number;
    targetNode: number;
    // either + or * or X-Y (e.g. 2-5)
    repetition: string;
    connectorHeight: number;
};

type HeightData = {
    minYHeight: number;
    maxYHeight: number;
    groupHeight: number;
};

type WidthData = {
    minXWidth: number;
    maxXWidth: number;
    groupWidth: number;
};

type LastAndFirstElement = {
    lastElement: Node | undefined;
    firstElement: Node | undefined;
};

export default class DiagramController extends Controller {
    private _nodeDistance = 100;
    private _nodeMinWidth = 30;
    private _nodeExtraWidthPerChar = 16.5;
    private _nodeMinHeight = 80;
    private _optionalNodeMargin = this._nodeDistance / 4;
    private _groupHeightPadding = this._nodeDistance / 2;
    private _anchorHeight = 50;
    private _repetitionConnectorPadding = 20;
    private _alternationPadding = 20;

    static dummyData = [
        'dummyDataCharacterClass',
        'dummyDataSingleStateMerging',
        'dummyDataGroups1',
        'dummyDataGroups2',
        'dummyDataGroups3',
        'dummyDataGroups4',
        'dummyDataAlternation1',
        'dummyDataAlternation2',
        'dummyDataAlternation3',
        'dummyDataAlternation4',
        'dummyDataAlternation5',
        'dummyDataAlternation6',
        'dummyDataRepetition1',
        'dummyDataRepetition2',
        'dummyDataRepetition3',
        'dummyDataRepetition4',
        'dummyDataRepetition5',
        'dummyDataRepetition6',
    ];

    static getMockObject(mockName: string): TrexMockNode | undefined {
        switch (mockName) {
            case 'dummyDataCharacterClass':
                return dummyDataCharacterClass;
            case 'dummyDataSingleStateMerging':
                return dummyDataSingleStateMerging;
            case 'dummyDataGroups4':
                return dummyDataGroups4;
            case 'dummyDataGroups3':
                return dummyDataGroups3;
            case 'dummyDataGroups2':
                return dummyDataGroups2;
            case 'dummyDataGroups1':
                return dummyDataGroups1;
            case 'dummyDataAlternation1':
                return dummyDataAlternation1;
            case 'dummyDataAlternation2':
                return dummyDataAlternation2;
            case 'dummyDataAlternation3':
                return dummyDataAlternation3;
            case 'dummyDataAlternation4':
                return dummyDataAlternation4;
            case 'dummyDataAlternation5':
                return dummyDataAlternation5;
            case 'dummyDataAlternation6':
                return dummyDataAlternation6;
            case 'dummyDataRepetition1':
                return dummyDataRepetition1;
            case 'dummyDataRepetition2':
                return dummyDataRepetition2;
            case 'dummyDataRepetition3':
                return dummyDataRepetition3;
            case 'dummyDataRepetition4':
                return dummyDataRepetition4;
            case 'dummyDataRepetition5':
                return dummyDataRepetition5;
            case 'dummyDataRepetition6':
                return dummyDataRepetition6;
            default:
                return undefined;
        }
    }

    public convertRegexToDiagram(tree: RootNode | undefined, mockDataId: number | undefined): Elements {
        const startY = 2000;
        let lastPosition = { x: 40, y: startY + 25 };
        const nodeArray: Node[] = [];
        const elements: Elements = [];
        const connectorHandlings: ConnectorHandling[] = [];
        const repetitionConnectors: RepetitionConnector[] = [];

        let data: RootNode | TrexMockNode | undefined;

        if (!mockDataId) mockDataId = 0;

        if (this.devMode) {
            const mockData = DiagramController.getMockObject(DiagramController.dummyData[mockDataId]);
            if (mockData) {
                data = mockData;
            }
        } else {
            data = tree;
        }

        if (!data) return elements;

        // push startNode
        nodeArray.push({
            id: '0',
            type: 'startNode',
            position: lastPosition,
            style: { width: 30, height: 30 },
        });
        lastPosition = { x: 70 + this._nodeDistance, y: startY };

        // add all elements between start and end node
        const lastElement = this.addElementsRecursive({
            elementArray: nodeArray,
            nodeTree: data.children,
            currentIndex: 0,
            lastPosition,
            connectorHandlings,
            repetitionConnectors,
        });

        // push endNode
        nodeArray.push({
            id: nodeArray.length.toString(),
            type: 'endNode',
            position: { x: lastElement.lastPosition.x, y: startY + 25 },
            style: { width: 30, height: 30 },
        });
        elements.push(...nodeArray);

        const divergeConnectors: ConnectorHandling[] = connectorHandlings.filter(
            (connector) => connector.sourceNodes.length === 1
        );
        const divergeConnectorIds: number[] = ([] as number[]).concat(
            ...divergeConnectors.map((value) => value.sourceNodes)
        );

        const mergeConnectors: ConnectorHandling[] = connectorHandlings.filter(
            (connector) => connector.targetNodes.length === 1 && connector.sourceNodes.length > 1
        );
        const mergeConnectorIds: number[] = ([] as number[]).concat(
            ...mergeConnectors.map((value) => value.sourceNodes)
        );

        for (let i = 0; i < nodeArray.length - 1; i++) {
            const currNode = nodeArray[i];
            let specialConnectorHandling = false;

            // 1:n and non-default 1:1 connections
            specialConnectorHandling = this.createSpecialConnectors(
                divergeConnectorIds,
                elements,
                i,
                divergeConnectors,
                currNode,
                false
            );

            // 1:n connections
            specialConnectorHandling =
                this.createSpecialConnectors(mergeConnectorIds, elements, i, mergeConnectors, currNode, true) ||
                specialConnectorHandling;

            if (specialConnectorHandling) continue;
            const nextNode = nodeArray[i + 1];

            // push default connectors
            elements.push({
                id: `e${currNode.id + '-' + nextNode.id}`,
                source: currNode.id,
                target: nextNode.id,
                type: 'trexDefault',
                data: { xOffset: this.calcDefaultXOffset(currNode, nextNode.position) },
            });
        }

        // add repetition connectors

        this.createRepetitionConnectors(repetitionConnectors, nodeArray, elements);

        return elements;
    }

    private addElementsRecursive(diagramData: DiagramData, onlyOneChild: boolean = false): DiagramData {
        const currentNode = diagramData.nodeTree[diagramData.currentIndex];
        if (!currentNode) {
            return diagramData;
        }

        let currResult;
        switch (currentNode.type) {
            case NodeType.MASKEDCHAR:
            case NodeType.SINGLESTATE:
                currResult = this.handleSingleStateNode(diagramData, onlyOneChild);
                break;
            case NodeType.ANYCHAR:
                currResult = this.handleAnyCharNode(diagramData);
                break;
            case NodeType.CHARACTERCLASS:
                currResult = this.handleCharacterClassNode(diagramData);
                break;
            case NodeType.NEGATION:
                currResult = this.handleNegationNode(diagramData);
                break;
            case NodeType.MULTIPLESTATES:
                currResult = this.handleMultipleStatesNode(diagramData);
                break;
            case NodeType.GROUP:
                currResult = this.handleGroupNode(diagramData);
                break;
            case NodeType.REPETITION:
                currResult = this.handleRepetitionNode(diagramData);
                break;
            case NodeType.ALTERNATION:
                currResult = this.handleAlternationNode(diagramData);
                break;
            case NodeType.ANCHORING:
                currResult = this.handleAnchoringNode(diagramData);
                break;
            case NodeType.NONE:
                currResult = this.handleNoneNode(diagramData);
                break;
            default:
                console.error(`NodeType ${currentNode.type} not implemented`);
                break;
        }

        if (currResult) {
            diagramData.elementArray = currResult.elementArray;
            diagramData.lastPosition = currResult.lastPosition;
            diagramData.connectorHandlings = currResult.connectorHandlings;
            diagramData.currentIndex = currResult.currentIndex;
            if (!onlyOneChild) {
                return this.addElementsRecursive(diagramData);
            }
        }

        return diagramData;
    }

    private createSpecialConnectors(
        connectorIds: number[],
        elements: Elements,
        currId: number,
        connectorHandlings: ConnectorHandling[],
        currNode: Node,
        merge: boolean
    ): boolean {
        let justInterNodeConnection = true;
        if (!connectorIds.includes(currId)) return false;
        const filteredConnectors = connectorHandlings.filter((connector) => connector.sourceNodes.includes(currId));
        filteredConnectors.forEach((connector) => {
            const multipleNodes = merge ? connector.sourceNodes : connector.targetNodes;
            multipleNodes.forEach((targetNode, index) => {
                const existConnectorIds = elements.map((connectorElement) => connectorElement.id);
                const from = merge ? connector.sourceNodes[index].toString() : currNode.id;
                const to = merge ? connector.targetNodes[0].toString() : targetNode.toString();
                if (from !== to) {
                    justInterNodeConnection = false;
                }
                const connectorID = `e${from + '-' + to}`;
                if (existConnectorIds.includes(connectorID)) {
                    const connector = elements.filter((connector) => connector.id === connectorID)[0];
                    const removeIndex = elements.indexOf(connector);
                    elements.splice(removeIndex, 1);
                }
                let xOffset: number;
                if (connector.xOffset === undefined) {
                    xOffset = 0;
                } else {
                    xOffset = merge ? connector.xOffset[index] : connector.xOffset[0];
                }
                elements.push({
                    id: connectorID,
                    source: merge ? connector.sourceNodes[index].toString() : currNode.id,
                    target: merge ? connector.targetNodes[0].toString() : targetNode.toString(),
                    sourceHandle: connector.sourceConnector,
                    targetHandle: connector.targetConnector,
                    type: 'trexDefault',
                    data: { xOffset },
                });
            });
        });

        return !justInterNodeConnection;
    }

    private createRepetitionConnectors(
        repetitionConnectors: RepetitionConnector[],
        nodeArray: Node[],
        elements: Elements
    ) {
        if (repetitionConnectors.length === 0) return;

        // get deepest repetition connector and add invisible node to let fitToView know how to fit
        let currDeepest: RepetitionConnector | undefined = undefined;
        let currDeepestValue: number = 0;
        for (const repetitionConnector of repetitionConnectors) {
            const sourceNode = nodeArray[repetitionConnector.sourceNode];
            const targetNode = nodeArray[repetitionConnector.targetNode];
            if (!sourceNode || !targetNode) continue;
            const deepestSource = sourceNode.position.y + this.getElementHeight(sourceNode);
            let deepestTarget: number;
            if (sourceNode.id === targetNode.id) {
                deepestTarget = deepestSource;
            } else {
                deepestTarget = targetNode.position.y + this.getElementHeight(targetNode);
            }
            const deepest = Math.max(deepestSource, deepestTarget);
            if (!currDeepest || deepest > currDeepestValue) {
                currDeepest = repetitionConnector;
                currDeepestValue = deepest;
            }
        }

        if (currDeepest) {
            const deepestSource = nodeArray[currDeepest.sourceNode];
            const pos = { x: deepestSource.position.x, y: currDeepestValue };
            elements.push(
                this.newNode(nodeArray.length, 'invisible', pos, '', this._nodeMinWidth, this._nodeMinHeight / 2)
            );
        }

        // add repetition connectors

        repetitionConnectors.forEach((repetitionConnector) => {
            elements.push({
                id: `e${repetitionConnector.sourceNode + '-' + repetitionConnector.targetNode}`,
                source: repetitionConnector.sourceNode.toString(),
                target: repetitionConnector.targetNode.toString(),
                data: { height: repetitionConnector.connectorHeight, repetition: repetitionConnector.repetition },
                type: 'repetition',
            });
        });
    }

    private handleSimpleTextNode(
        diagramData: DiagramData,
        label: string,
        type: string = 'simpleText',
        height: number = this._nodeMinHeight
    ): DiagramData {
        const width = this.calcElementWidth(label, this._nodeExtraWidthPerChar, this._nodeMinWidth);
        diagramData.elementArray.push(
            this.newNode(
                diagramData.elementArray.length,
                type,
                diagramData.lastPosition,
                label,
                width,
                height,
                diagramData.negation
            )
        );
        diagramData.lastPosition = {
            x: diagramData.lastPosition.x + width + this._nodeDistance,
            y: diagramData.lastPosition.y,
        };
        diagramData.currentIndex++;
        return diagramData;
    }

    private handleSingleStateNode(diagramData: DiagramData, onlyOneChild: boolean): DiagramData {
        let currentNode;
        let label = '';
        do {
            currentNode = diagramData.nodeTree[diagramData.currentIndex];
            label += currentNode.type === NodeType.MASKEDCHAR ? currentNode.content.slice(1) : currentNode.content;
            if (++diagramData.currentIndex >= diagramData.nodeTree.length) break;
            if (onlyOneChild) break;
        } while (
            diagramData.nodeTree[diagramData.currentIndex].type === NodeType.SINGLESTATE ||
            diagramData.nodeTree[diagramData.currentIndex].type === NodeType.MASKEDCHAR
        );
        diagramData.currentIndex--;
        return this.handleSimpleTextNode(diagramData, label);
    }

    private handleAnyCharNode(diagramData: DiagramData): DiagramData {
        return this.handleSimpleTextNode(diagramData, 'any char', 'anyChar');
    }

    private handleCharacterClassNode(diagramData: DiagramData): DiagramData {
        const currentNode = diagramData.nodeTree[diagramData.currentIndex];
        const label = currentNode.content.slice(diagramData.negation ? 2 : 1, currentNode.content.length - 1);
        const width = Math.max(
            TrexCharacterClass.getMinWidth(diagramData.negation || false),
            this.calcElementWidth(label, this._nodeExtraWidthPerChar, this._nodeMinWidth)
        );
        diagramData.elementArray.push(
            this.newNode(
                diagramData.elementArray.length,
                'characterClass',
                diagramData.lastPosition,
                label,
                width,
                this._nodeMinHeight,
                diagramData.negation
            )
        );
        diagramData.lastPosition = {
            x: diagramData.lastPosition.x + width + this._nodeDistance,
            y: diagramData.lastPosition.y,
        };
        diagramData.currentIndex++;
        return diagramData;
    }

    private handleNegationNode(diagramData: DiagramData): DiagramData {
        const currentNode = diagramData.nodeTree[diagramData.currentIndex];
        const children = currentNode.children;
        if (!children) {
            throw Error('no children in Negation');
        } else {
            diagramData.negation = true;
            return this.handleMultipleStatesNode(diagramData);
        }
    }

    private handleRepetitionNode(diagramData: DiagramData): DiagramData {
        const currentNode = diagramData.nodeTree[diagramData.currentIndex];

        const repetitionStart = diagramData.elementArray.length;
        const elements = diagramData.currentAlternationSource
            ? diagramData.elementArray.slice(diagramData.currentAlternationSource)
            : diagramData.elementArray;
        const sourceId = this.getId(this.getLastElement(elements));
        const alternationSourceId = diagramData.alternationSource;
        const startPosition = { ...diagramData.lastPosition };

        if (!(currentNode instanceof RepetitionExpressionNode) && currentNode instanceof TrexNode) {
            throw Error('handleRepetition() called without RepetitionExpressionNode');
        }

        const children = currentNode.children;
        if (!children) {
            diagramData.currentIndex++;
            return diagramData;
        }

        // calc repetition data
        const minRepetition = currentNode.minRepetition;
        const maxRepetition = currentNode.maxRepetition;
        let repetition = '';
        if (maxRepetition === undefined && minRepetition === 1) {
            repetition = '+';
        } else if (maxRepetition === undefined && minRepetition === 0) {
            repetition = '*';
        } else if (maxRepetition === 1 && minRepetition === 0) {
            repetition = '?';
        } else if (minRepetition != undefined && maxRepetition != undefined) {
            repetition = `${Math.max(minRepetition - 1, 0)}-${Math.max(maxRepetition - 1, 0)}`;
        }

        const childDiagramData = { ...diagramData };
        childDiagramData.currentIndex = 0;
        childDiagramData.nodeTree = children;
        const data = this.addElementsRecursive(childDiagramData);
        diagramData.lastPosition = data.lastPosition;
        diagramData.currentIndex++;

        const newElements = diagramData.elementArray.slice(repetitionStart);
        const firstAndLastElement = this.getLastAndFirstElement(newElements);
        const lastElement = firstAndLastElement.lastElement;
        const firstElement = firstAndLastElement.firstElement;
        const elementsGroupHeight = this.calcElementGroupHeight(newElements);

        // calc connector height for groups (handles may not be at 50%)

        let connectorHeight = elementsGroupHeight.groupHeight / 2 + this._repetitionConnectorPadding;
        if (firstElement && lastElement && firstElement.id === lastElement.id && firstElement.type === 'group') {
            connectorHeight =
                elementsGroupHeight.groupHeight * ((100 - lastElement?.data.relSourceHandlePos) / 100) +
                this._repetitionConnectorPadding;
        }

        const repetitionNodeId = minRepetition === 0 ? repetitionStart + 1 : repetitionStart;
        if (!maxRepetition || maxRepetition > 1) {
            diagramData.repetitionConnectors.push({
                repetition: repetition,
                sourceNode: repetitionNodeId,
                targetNode: repetitionNodeId,
                connectorHeight: connectorHeight,
            });
            if (firstElement?.type === 'group' && diagramData.elementArray[sourceId].type === 'group') {
                newElements.forEach((node) => {
                    node.position.x += this._nodeDistance / 4;
                });
                diagramData.lastPosition.x += this._nodeDistance / 4;
            }
            if (lastElement?.type === 'group' && diagramData.elementArray[repetitionStart].type === 'group') {
                diagramData.lastPosition.x += this._nodeDistance / 4;
            }
        }
        if (minRepetition === 0) {
            this.addOptional(diagramData, newElements, sourceId, startPosition, maxRepetition, alternationSourceId);
        }
        return diagramData;
    }

    private addOptional(
        diagramData: DiagramData,
        newElements: Node[],
        sourceId: number,
        startPosition: XYPosition,
        maxRepetition: number | undefined,
        alternationSourceId: number | undefined
    ) {
        const height = this.calcElementGroupHeight(newElements);
        const width = this.calcElementGroupWidth(newElements);
        const firstAndLastElement = this.getLastAndFirstElement(newElements);
        let firstElementId = this.getId(firstAndLastElement.firstElement);
        let lastElementId = this.getId(firstAndLastElement.lastElement);
        const groupBefore =
            diagramData.elementArray[sourceId].type === 'group' ||
            diagramData.elementArray[sourceId].type === 'merge' ||
            (alternationSourceId &&
                (diagramData.elementArray[alternationSourceId].type === 'group' ||
                    diagramData.elementArray[alternationSourceId].type === 'merge'));

        // add invisible element before optional path
        const invisibleElementBeforeId = firstElementId;
        const invisibleElementBeforePos = { ...diagramData.elementArray[firstElementId].position };
        // const startPosYDiff = startPosition.y - invisibleElementBeforePos.y;
        invisibleElementBeforePos.x -=
            this._nodeMinWidth + this._nodeDistance - (groupBefore ? this._nodeDistance * 0.75 : 5);
        invisibleElementBeforePos.y = startPosition.y;
        const invisibleElementBefore = this.newNode(
            invisibleElementBeforeId,
            'diverge',
            invisibleElementBeforePos,
            '',
            this._nodeMinWidth,
            this._nodeMinHeight,
            diagramData.negation
        );

        // add this element at the specific place
        diagramData.elementArray.splice(firstElementId, 0, invisibleElementBefore);
        diagramData.elementArray.slice(firstElementId + 1).forEach((node) => {
            node.id = (this.getId(node) + 1).toString();
        });
        firstElementId++;
        lastElementId++;

        diagramData.elementArray.slice(invisibleElementBeforeId).forEach((node) => {
            node.position.x += this._nodeDistance * 0.5;
        });
        diagramData.elementArray.slice(invisibleElementBeforeId + 1).forEach((node) => {
            if (!groupBefore) {
                node.position.x -= this._nodeDistance / 2;
            } else {
                node.position.x += this._nodeDistance / 4;
            }
        });

        // change IDs of connectors
        if (diagramData.alternationSource && diagramData.alternationSource > invisibleElementBeforeId) {
            diagramData.alternationSource++;
        }
        if (diagramData.currentAlternationSource && diagramData.currentAlternationSource > invisibleElementBeforeId) {
            diagramData.currentAlternationSource++;
        }
        diagramData.connectorHandlings.forEach((connector) => {
            connector.sourceNodes.forEach((sourceNodeId, index) => {
                if (
                    (connector.targetNodes.length <= 1 && sourceNodeId >= invisibleElementBeforeId) ||
                    (connector.targetNodes.length > 1 && sourceNodeId > invisibleElementBeforeId)
                ) {
                    connector.sourceNodes.splice(index, 1, sourceNodeId + 1);
                }
            });
            connector.targetNodes.forEach((targetNodeID, index) => {
                if (targetNodeID >= invisibleElementBeforeId) {
                    connector.targetNodes.splice(index, 1, targetNodeID + 1);
                }
            });
        });
        diagramData.repetitionConnectors.forEach((connector, index) => {
            if ((!maxRepetition || maxRepetition > 1) && index === diagramData.repetitionConnectors.length - 1) {
                return;
            }
            if (connector.sourceNode >= invisibleElementBeforeId) {
                connector.sourceNode++;
            }
            if (connector.targetNode >= invisibleElementBeforeId) {
                connector.targetNode++;
            }
        });

        const invisibleMidElementPos = { ...diagramData.elementArray[firstElementId].position };
        invisibleMidElementPos.y = startPosition.y + this._nodeMinHeight / 2 + this._optionalNodeMargin;

        // push invisible node to represent optional path
        const invisibleElementMidId = diagramData.elementArray.length;
        diagramData.elementArray.push(
            this.newNode(
                invisibleElementMidId,
                'repetition',
                invisibleMidElementPos,
                '',
                width ? width.groupWidth : this._nodeMinWidth,
                this._nodeMinHeight, //height.groupHeight, //+
                //height.groupHeight / 2 - this._connectorOptionalPadding + this._optionalNodeMargin,
                diagramData.negation
            )
        );

        // add invisible element after optional path
        diagramData.lastPosition.x -= this._nodeMinWidth;
        const invisibleElementAfterId = diagramData.elementArray.length;
        const invisibleElementAfterPos = { ...diagramData.elementArray[firstElementId].position };
        invisibleElementAfterPos.x += (width ? width.groupWidth : this._nodeMinWidth) + this._nodeDistance * 0.5;
        invisibleElementAfterPos.y = startPosition.y;
        const invisibleElementAfter = this.newNode(
            invisibleElementAfterId,
            'merge',
            invisibleElementAfterPos,
            '',
            this._nodeMinWidth,
            this._nodeMinHeight,
            diagramData.negation
        );

        diagramData.elementArray.push(invisibleElementAfter);

        // adjust height
        let yTranslation = height.groupHeight / 2 + this._optionalNodeMargin;
        if (firstElementId === lastElementId && diagramData.elementArray[lastElementId].type === 'group') {
            yTranslation =
                height.groupHeight * ((100 - diagramData.elementArray[lastElementId]?.data.relSourceHandlePos) / 100) +
                this._optionalNodeMargin;
        }
        newElements.forEach((node) => {
            node.position.y -= yTranslation;
        });

        diagramData.lastPosition = { ...diagramData.lastPosition };

        // remove old connections
        const existingConnectors = diagramData.connectorHandlings.filter(
            (connector) =>
                (connector.targetNodes.includes(firstElementId) && !connector.targetConnector) ||
                connector.targetNodes.includes(invisibleElementBeforeId) ||
                connector.targetNodes.includes(invisibleElementMidId)
        );

        existingConnectors.forEach((connector) => {
            diagramData.connectorHandlings.splice(diagramData.connectorHandlings.indexOf(connector), 1);
        });

        // push connector handlings for optional path
        // diverge connector
        diagramData.connectorHandlings.push({
            sourceNodes: [invisibleElementBeforeId],
            targetNodes: [firstElementId, invisibleElementMidId],
            xOffset: [0],
        });

        const xOffset =
            diagramData.elementArray[invisibleElementAfterId].position.x -
            (diagramData.elementArray[lastElementId].position.x +
                this.getElementWidth(diagramData.elementArray[lastElementId]) +
                10);
        // merge connector
        diagramData.connectorHandlings.push({
            sourceNodes: [lastElementId, invisibleElementMidId],
            targetNodes: [invisibleElementAfterId],
            xOffset: [xOffset, xOffset],
        });

        // connect invisible nodes with themself
        diagramData.connectorHandlings.push({
            sourceNodes: [invisibleElementMidId],
            targetNodes: [invisibleElementMidId],
        });

        diagramData.connectorHandlings.push({
            sourceNodes: [invisibleElementBeforeId],
            targetNodes: [invisibleElementBeforeId],
        });

        diagramData.connectorHandlings.push({
            sourceNodes: [invisibleElementAfterId],
            targetNodes: [invisibleElementAfterId],
        });

        if (groupBefore) {
            diagramData.connectorHandlings.push({
                sourceNodes: [sourceId],
                targetNodes: [invisibleElementBeforeId],
            });
        }

        diagramData.lastPosition.x = invisibleElementAfter.position.x + this._nodeMinWidth * 2;
        diagramData.lastPosition.y = startPosition.y;
    }

    private handleMultipleStatesNode(diagramData: DiagramData): DiagramData {
        const currentNode = diagramData.nodeTree[diagramData.currentIndex];
        const children = currentNode.children;
        if (!children) {
            diagramData.currentIndex++;
            return diagramData;
        }

        const previousPosition = diagramData.lastPosition;

        const childDiagramData = { ...diagramData };
        childDiagramData.currentIndex = 0;
        childDiagramData.nodeTree = children;
        const lastElement = this.addElementsRecursive(childDiagramData);

        const width = lastElement.lastPosition.x - previousPosition.x - this._nodeDistance;
        diagramData.lastPosition = {
            x: diagramData.lastPosition.x + width + this._nodeDistance,
            y: diagramData.lastPosition.y,
        };
        diagramData.currentIndex++;
        return diagramData;
    }

    private handleGroupNode(diagramData: DiagramData): DiagramData {
        const currentNode = diagramData.nodeTree[diagramData.currentIndex];
        const label = ExpressionUtils.getMatchGroupNameByNode(currentNode);
        const children = currentNode.children;
        const startPosition = { ...diagramData.lastPosition };
        const elementBefore = this.getLastElement(diagramData.elementArray);

        diagramData.lastPosition.x += this._nodeDistance / 4;
        let groupPosition = {
            x: diagramData.lastPosition.x - this._nodeDistance / 2,
            y: diagramData.lastPosition.y,
        };

        // if group is empty
        if (!children || children.length === 0 || children[0].type === NodeType.NONE) {
            return this.addEmptyGroup(diagramData, groupPosition, label, currentNode);
        }

        //group is not empty
        const groupId = diagramData.elementArray.length;

        // push pseudo group (will get removed using splice), used because addElementsRecursive
        // must know there is a group in front of the next added elements (to handle the connectors correctly)
        diagramData.elementArray.push(this.newNode(groupId, 'group', groupPosition, '', 0, 0, diagramData.negation));

        const childDiagramData = { ...diagramData };
        childDiagramData.nodeTree = children;
        childDiagramData.currentIndex = 0;
        const result = this.addElementsRecursive(childDiagramData);
        const items = diagramData.elementArray.slice(groupId + 1);
        const heightData = this.calcElementGroupHeight(items);
        const firstAndLastInGroup = this.getLastAndFirstElement(items);
        const firstElementInGroup = firstAndLastInGroup.firstElement;
        const lastElementInGroup = firstAndLastInGroup.lastElement;

        const groupWidth = Math.max(
            result.lastPosition.x -
                groupPosition.x -
                (lastElementInGroup?.type === 'merge' ? -20 : this._nodeDistance / 2),
            TrexGroup.getMinWidth(label)
        );
        const groupHeight = heightData.groupHeight + this._groupHeightPadding * 2;

        // calc height and pos

        groupPosition = {
            x: groupPosition.x,
            y: heightData.minYHeight - this._groupHeightPadding,
        };

        // calc pos for handles (need to be relative)
        let relHandlePos = 50;
        let fixHandlePos;
        if (
            diagramData.connectorHandlings.filter(
                (connector) =>
                    connector.sourceNodes.includes(groupId) &&
                    connector.sourceConnector &&
                    connector.targetNodes.length > 0
            ).length <= 0
        ) {
            if (firstElementInGroup) {
                if (firstElementInGroup.data.relTargetHandlePos) {
                    fixHandlePos =
                        firstElementInGroup.position.y +
                        (this.getElementHeight(firstElementInGroup) * firstElementInGroup.data.relTargetHandlePos) /
                            100;
                } else {
                    fixHandlePos = firstElementInGroup.position.y + this.getElementHeight(firstElementInGroup) / 2;
                }
            }
        } else {
            fixHandlePos = (elementBefore ? elementBefore.position.y : 0) + this.getElementHeight(elementBefore) / 2;
        }

        if (fixHandlePos) {
            relHandlePos = ((fixHandlePos - groupPosition.y) / groupHeight) * 100;
        }

        diagramData.elementArray.splice(
            groupId,
            1,
            this.newNode(
                groupId,
                'group',
                groupPosition,
                label,
                groupWidth,
                groupHeight,
                diagramData.negation,
                ExpressionUtils.getGroupNumber(currentNode),
                undefined,
                relHandlePos,
                relHandlePos
            )
        );

        // remove old connectors
        const existingConnectors = diagramData.connectorHandlings.filter((connector) =>
            connector.targetNodes.includes(diagramData.elementArray.length)
        );

        existingConnectors.forEach((connector) => {
            diagramData.connectorHandlings.splice(diagramData.connectorHandlings.indexOf(connector), 1);
        });

        // add new connectors
        if (childDiagramData.nodeTree[0].type !== NodeType.ALTERNATION) {
            const groupConnectorLeftOutgoing: ConnectorHandling = {
                sourceNodes: [groupId],
                targetNodes: [groupId + 1],
                sourceConnector: 's2',
                xOffset: [
                    this.calcDefaultXOffsetByPosition(
                        diagramData.elementArray[groupId].position,
                        diagramData.elementArray[groupId + 1].position
                    ),
                ],
            };

            const groupTargetHandlePos = { ...diagramData.elementArray[groupId].position };
            groupTargetHandlePos.x += groupWidth;
            const groupConnectorRightIncoming: ConnectorHandling = {
                sourceNodes: [this.getId(lastElementInGroup)],
                targetNodes: [groupId],
                targetConnector: 't2',
                xOffset: [
                    this.calcDefaultXOffset(
                        diagramData.elementArray[this.getId(lastElementInGroup)],
                        groupTargetHandlePos
                    ),
                ],
            };
            diagramData.connectorHandlings.push(groupConnectorLeftOutgoing, groupConnectorRightIncoming);
        }

        const groupConnectorRightOutgoing: ConnectorHandling = {
            sourceNodes: [groupId],
            targetNodes: [diagramData.elementArray.length],
        };

        diagramData.connectorHandlings.push(groupConnectorRightOutgoing);

        diagramData.currentIndex++;
        diagramData.lastPosition = {
            x: diagramData.lastPosition.x + groupWidth + this._nodeDistance / 4,
            y: startPosition.y,
        };
        return diagramData;
    }

    private addEmptyGroup(
        diagramData: DiagramData,
        groupPosition: XYPosition,
        label: string,
        currentNode: TrexNode | TrexMockNode
    ): DiagramData {
        diagramData.elementArray.push(
            this.newNode(
                diagramData.elementArray.length,
                'group',
                groupPosition,
                label,
                TrexGroup.getMinWidth(label),
                this._nodeMinHeight,
                diagramData.negation,
                ExpressionUtils.getGroupNumber(currentNode)
            )
        );
        diagramData.lastPosition = {
            x: diagramData.lastPosition.x + TrexGroup.getMinWidth(label) + this._nodeDistance / 4,
            y: diagramData.lastPosition.y,
        };
        diagramData.connectorHandlings.push({
            sourceNodes: [diagramData.elementArray.length - 1],
            targetNodes: [diagramData.elementArray.length - 1],
        });
        diagramData.connectorHandlings.push({
            sourceNodes: [diagramData.elementArray.length - 1],
            targetNodes: [diagramData.elementArray.length],
        });
        diagramData.currentIndex++;
        return diagramData;
    }

    private getAlternationsAsArray(diagramData: DiagramData): DiagramData[] | undefined {
        let currentNode = diagramData.nodeTree[diagramData.currentIndex];
        const children = currentNode.children;
        if (!children) return undefined;
        if (children.length !== 2) return undefined;

        const alternations: DiagramData[] = [];
        const firstChild = { ...diagramData };
        firstChild.nodeTree = children;
        firstChild.currentIndex = 0;
        alternations.push(firstChild);

        currentNode = children[1];
        let currentDiagramData = firstChild;

        while (currentNode.type === NodeType.ALTERNATION) {
            if (currentNode.children && currentNode.children.length === 1) {
                currentDiagramData = {
                    currentIndex: 0,
                    connectorHandlings: diagramData.connectorHandlings,
                    elementArray: diagramData.elementArray,
                    lastPosition: diagramData.lastPosition,
                    nodeTree: currentNode.children,
                    negation: diagramData.negation,
                    repetitionConnectors: diagramData.repetitionConnectors,
                };
                alternations.push(currentDiagramData);
                return alternations;
            } else if (currentNode.children && currentNode.children.length === 2) {
                currentDiagramData = {
                    currentIndex: 0,
                    connectorHandlings: diagramData.connectorHandlings,
                    elementArray: diagramData.elementArray,
                    lastPosition: diagramData.lastPosition,
                    nodeTree: currentNode.children,
                    negation: diagramData.negation,
                    repetitionConnectors: diagramData.repetitionConnectors,
                };
                alternations.push(currentDiagramData);
                currentNode = currentNode.children[1];
            }
        }
        currentDiagramData = { ...currentDiagramData };
        currentDiagramData.currentIndex = 1;
        alternations.push(currentDiagramData);
        return alternations;
    }

    private centerAlternationHorizontally(alternationItems: Node[][]): void {
        // find longest path
        const pathWidths: WidthData[] = [];
        alternationItems.forEach((nodes) => {
            const widthData = this.calcElementGroupWidth(nodes);
            if (widthData) pathWidths.push(widthData);
        });

        let longestPathWidth: WidthData = { groupWidth: 0, maxXWidth: 0, minXWidth: 0 };
        let longestPathIndex: number | undefined = undefined;
        pathWidths.forEach((pathWidth, i) => {
            if (!longestPathWidth || pathWidth.groupWidth > longestPathWidth.groupWidth) {
                longestPathWidth = pathWidth;
                longestPathIndex = i;
            }
        });

        // center
        if (longestPathIndex !== undefined) {
            for (let i = 0; i < alternationItems.length; i++) {
                const currPathWidth = pathWidths[i];
                const padding = (longestPathWidth.groupWidth - currPathWidth.groupWidth) / 2;
                let extraPadding = 0;
                let nodeTypeBefore: string | undefined = undefined;
                alternationItems[i].forEach((node) => {
                    if (node.type === 'group' && nodeTypeBefore != 'diverge') {
                        extraPadding += this._nodeDistance / 4;
                    }
                    node.position.x += padding + extraPadding;
                    nodeTypeBefore = node.type;
                });
            }
        }
    }

    private centerAlternationVertically(
        diagramData: DiagramData,
        alternationItems: Node[][],
        divergeConnector: ConnectorHandling
    ): void {
        const allAlternations = diagramData.elementArray.slice(divergeConnector.sourceNodes[0] + 1);
        const firstPathFirstElement = this.getFirstElement(alternationItems[0]);
        const firstPathFirstElementMid =
            (firstPathFirstElement ? firstPathFirstElement.position.y : this._nodeMinHeight) +
            this.getElementHeight(firstPathFirstElement) / 2;
        const lastPathFirstElement = this.getFirstElement(alternationItems[alternationItems.length - 1]);
        const lastPathFirstElementMid =
            (lastPathFirstElement ? lastPathFirstElement.position.y : this._nodeMinHeight) +
            this.getElementHeight(lastPathFirstElement) / 2;
        const lastPathHeight = this.calcElementGroupHeight(alternationItems[alternationItems.length - 1]);
        allAlternations.forEach((node) => {
            node.position.y +=
                lastPathHeight.groupHeight / 2 + (lastPathFirstElementMid - firstPathFirstElementMid) / 2;
        });
    }

    private handleAlternationNode(diagramData: DiagramData): DiagramData {
        const currentNode = diagramData.nodeTree[diagramData.currentIndex];

        // Zero or one child => Handle as MultipleStates
        const children = currentNode.children;
        if (!children) {
            diagramData.currentIndex++;
            return diagramData;
        }
        if (children.length === 1) {
            return this.handleMultipleStatesNode(diagramData);
        } else if (children.length === 2) {
            const previousPosition = { x: diagramData.lastPosition.x, y: diagramData.lastPosition.y };
            const divergeConnector: ConnectorHandling = {
                sourceNodes: [diagramData.elementArray.length - 1],
                targetNodes: [],
            };
            const mergeConnector: ConnectorHandling = { sourceNodes: [], targetNodes: [] };

            const alternationNodes = this.getAlternationsAsArray(diagramData);
            if (!alternationNodes) throw Error('Error during processing alternation');

            const alternationLastPositions: XYPosition[] = [];
            const alternationItems: Node[][] = [];
            const alternationSource = diagramData.elementArray.length - 1;

            for (let currIndex = 0; currIndex < alternationNodes?.length; currIndex++) {
                const currAlternation = alternationNodes[currIndex];
                const currId = diagramData.elementArray.length;

                // set start pos
                diagramData.lastPosition = { x: previousPosition.x, y: previousPosition.y };
                currAlternation.lastPosition = diagramData.lastPosition;
                currAlternation.currentAlternationSource = currId;
                currAlternation.alternationSource = alternationSource;

                // adding elements
                const lastElement = this.addElementsRecursive({ ...currAlternation }, true);

                // save elements for merge lastPos calculation and height calc
                const items = diagramData.elementArray.slice(currId, lastElement.elementArray.length);
                alternationItems.push(items);

                // calc width and position
                const widthData = this.calcElementGroupWidth(items);
                const width = widthData ? widthData.groupWidth : this._nodeMinWidth;

                // calc height and move previous y pos
                // First step is moving just to top (vertical centering happens later)
                const firstElement = this.getFirstElement(items);
                const elementArrayBeforeNow = lastElement.elementArray.slice(
                    divergeConnector.sourceNodes[0] + 1,
                    this.getId(firstElement)
                );

                const heightData = this.calcElementGroupHeight(items);
                const firstElementMid = firstElement
                    ? firstElement.position.y + this.getElementHeight(firstElement) / 2
                    : this._nodeMinHeight / 2;
                items.forEach((node) => {
                    node.position.y -= heightData.groupHeight - (firstElementMid - heightData.minYHeight);
                });
                elementArrayBeforeNow.forEach((node) => {
                    node.position.y -= heightData.groupHeight + this._alternationPadding;
                });

                const savedLastPosition = {
                    x: diagramData.lastPosition.x + width + this._nodeDistance,
                    y: diagramData.lastPosition.y,
                };
                alternationLastPositions.push(savedLastPosition);

                // update diagramData
                diagramData.elementArray = lastElement.elementArray;
                diagramData.connectorHandlings = lastElement.connectorHandlings;
                delete diagramData.currentAlternationSource;

                // connector data
                divergeConnector.targetNodes.push(currId);
                mergeConnector.sourceNodes.push(parseInt(this.getLastElement(items)?.id as string));
            }

            // center horizontally

            this.centerAlternationHorizontally(alternationItems);

            // center vertically all alternation paths (move down)

            this.centerAlternationVertically(diagramData, alternationItems, divergeConnector);

            // delete existing connector from ending nodes

            const existingConnectors = diagramData.connectorHandlings.filter(
                (connector) =>
                    (connector.targetNodes.some((targetNodeId) =>
                        divergeConnector.targetNodes.includes(targetNodeId)
                    ) ||
                        connector.targetNodes.includes(diagramData.elementArray.length)) &&
                    !connector.targetConnector &&
                    connector.sourceNodes[0] !== connector.targetNodes[0]
            );

            existingConnectors.forEach((connector) => {
                diagramData.connectorHandlings.splice(diagramData.connectorHandlings.indexOf(connector), 1);
            });

            // end merge connector

            mergeConnector.targetNodes.push(diagramData.elementArray.length);

            // calc longest element
            let currLongest = 0;
            let lastNodeId: number = 0;
            for (let i = 0; i < alternationItems.length; i++) {
                const items = alternationItems[i];
                const lastElement = this.getLastElement(items);
                if (!lastElement) continue;
                const xPos = lastElement?.position.x + this.getElementWidth(lastElement);
                if (xPos > currLongest) {
                    currLongest = xPos;
                    lastNodeId = i;
                }
            }

            // adjust connectors, if alternation is inside of group
            if (diagramData.elementArray[divergeConnector.sourceNodes[0]].type === 'group') {
                divergeConnector.sourceConnector = 's2';
                mergeConnector.targetNodes = divergeConnector.sourceNodes;
                mergeConnector.targetConnector = 't2';
            }

            // adjust xOffset of merge and diverge connector
            const longestPathFirstElement = this.getFirstElement(alternationItems[lastNodeId]);
            if (longestPathFirstElement !== undefined) {
                divergeConnector.xOffset = [];
                mergeConnector.xOffset = [];
                const sourceNode = diagramData.elementArray[divergeConnector.sourceNodes[0]];
                const longestPathXOffset =
                    (longestPathFirstElement.position.x - (sourceNode.position.x + this.getElementWidth(sourceNode))) /
                    2;
                alternationItems.forEach((alternationPath) => {
                    const currPathFirstElement = this.getFirstElement(alternationPath);
                    if (currPathFirstElement) {
                        mergeConnector.xOffset?.push(
                            longestPathXOffset - (longestPathFirstElement.position.x - currPathFirstElement.position.x)
                        );
                    }
                });
                divergeConnector.xOffset?.push(longestPathXOffset);
            }

            diagramData.lastPosition = alternationLastPositions[lastNodeId];

            diagramData.connectorHandlings.push(divergeConnector, mergeConnector);

            diagramData.currentIndex++;
            return diagramData;
        } else {
            throw Error(`Alternation should only have max two children`);
        }
    }

    private handleAnchoringNode(diagramData: DiagramData): DiagramData {
        const currentNode = diagramData.nodeTree[diagramData.currentIndex];

        if (!(currentNode instanceof AnchorExpressionNode)) {
            throw Error('Anchoring node is not instance of AnchorExpressionNode');
        }

        if (currentNode.startAnchor) {
            this.addAnchor(diagramData, 'start of line');
        }

        this.handleMultipleStatesNode(diagramData);

        if (currentNode.endAnchor) {
            this.addAnchor(diagramData, 'end of line');
        }

        return diagramData;
    }

    private addAnchor(diagramData: DiagramData, label: string): DiagramData {
        const padding = (this._nodeMinHeight - this._anchorHeight) / 2;
        diagramData.lastPosition.y += padding;
        this.handleSimpleTextNode(diagramData, label, 'anchor', this._anchorHeight);
        diagramData.currentIndex--;
        diagramData.lastPosition.y -= padding;
        return diagramData;
    }

    private handleNoneNode(diagramData: DiagramData): DiagramData {
        diagramData.elementArray.push(
            this.newNode(
                diagramData.elementArray.length,
                'invisible',
                diagramData.lastPosition,
                '',
                this._nodeMinWidth,
                this._nodeMinHeight,
                diagramData.negation
            )
        );
        diagramData.connectorHandlings.push({
            sourceNodes: [diagramData.elementArray.length - 1],
            targetNodes: [diagramData.elementArray.length - 1],
        });
        diagramData.lastPosition = {
            x: diagramData.lastPosition.x + this._nodeMinWidth + this._nodeDistance,
            y: diagramData.lastPosition.y,
        };
        diagramData.currentIndex++;
        return diagramData;
    }

    private newNode(
        id: number,
        type: string,
        position: XYPosition,
        label: string,
        width: number,
        height: number,
        negation?: boolean,
        groupId?: number,
        xOffset?: number,
        relSourceHandlePos?: number,
        relTargetHandlePos?: number
    ): Node {
        return {
            id: id.toString(),
            type: type,
            position: position,
            data: { label, negation, groupId, xOffset, relSourceHandlePos, relTargetHandlePos },
            style: { width, height },
        };
    }

    // helper methods (not adding nodes, just calculating)

    private getLastElement(nodes: Node[]): Node | undefined {
        if (nodes.length === 0) return undefined;
        let currLastElement = nodes[0];
        let currLastElementX = nodes[0].position.x + this.getElementWidth(nodes[0]);
        for (let i = 1; i < nodes.length; i++) {
            const elementWidth = this.getElementWidth(nodes[i]);
            const nodeEndPosX = nodes[i].position.x + elementWidth;
            if (nodeEndPosX >= currLastElementX) {
                currLastElement = nodes[i];
                currLastElementX = nodeEndPosX;
            }
        }
        return currLastElement;
    }

    private getFirstElement(nodes: Node[]): Node | undefined {
        if (nodes.length === 0) return undefined;
        let currFirstElement = nodes[0];
        let currFirstElementX = nodes[0].position.x;
        for (let i = 1; i < nodes.length; i++) {
            if (nodes[i].position.x <= currFirstElementX) {
                currFirstElement = nodes[i];
                currFirstElementX = nodes[i].position.x;
            }
        }
        return currFirstElement;
    }

    private getLastAndFirstElement(nodes: Node[]): LastAndFirstElement {
        const lastAndFirstElement: LastAndFirstElement = { lastElement: undefined, firstElement: undefined };
        let currLastElementX: number | undefined;
        let currFirstElementX: number | undefined;
        for (let i = 0; i < nodes.length; i++) {
            const elementWidth = this.getElementWidth(nodes[i]);
            const nodeEndPosX = nodes[i].position.x + elementWidth;
            if (!currLastElementX || nodeEndPosX >= currLastElementX) {
                lastAndFirstElement.lastElement = nodes[i];
                currLastElementX = nodeEndPosX;
            }
            if (!currFirstElementX || nodes[i].position.x <= currFirstElementX) {
                lastAndFirstElement.firstElement = nodes[i];
                currFirstElementX = nodes[i].position.x;
            }
        }
        return lastAndFirstElement;
    }

    private calcElementWidth(label: string, multiplyWidth: number, defaultWidth: number): number {
        return label.length * multiplyWidth + defaultWidth;
    }

    private getElementHeight(element: Node | undefined): number {
        if (!element) return this._nodeMinHeight;
        return parseInt(element.style?.height as string);
    }

    private getElementWidth(element: Node | undefined): number {
        if (!element) return this._nodeMinWidth;
        return parseInt(element.style?.width as string);
    }

    private calcElementGroupHeight(elementArray: Node[]): HeightData {
        let maxYOfElementInGroup = undefined;
        let minYOfElementInGroup = undefined;
        for (let i = 0; i < elementArray.length; i++) {
            if (elementArray[i].style?.height) {
                const height = this.getElementHeight(elementArray[i]);
                if (!maxYOfElementInGroup || elementArray[i].position.y + height > maxYOfElementInGroup) {
                    maxYOfElementInGroup = elementArray[i].position.y + height;
                }
            }
            if (!minYOfElementInGroup || elementArray[i].position.y < minYOfElementInGroup) {
                minYOfElementInGroup = elementArray[i].position.y;
            }
        }

        if (!minYOfElementInGroup) minYOfElementInGroup = 100;
        if (!maxYOfElementInGroup) maxYOfElementInGroup = 100;

        const groupHeight = maxYOfElementInGroup - minYOfElementInGroup;
        return { minYHeight: minYOfElementInGroup, maxYHeight: maxYOfElementInGroup, groupHeight };
    }

    private calcElementGroupWidth(elementArray: Node[]): WidthData | undefined {
        const lastElement = this.getLastElement(elementArray);
        const firstElement = this.getFirstElement(elementArray);
        if (!firstElement) return undefined;
        if (!lastElement) return undefined;
        const minXWidth = firstElement.position.x;
        const maxXWidth = lastElement.position.x + this.getElementWidth(lastElement);
        const groupWidth = maxXWidth - minXWidth;
        return { minXWidth, maxXWidth, groupWidth };
    }

    private calcDefaultXOffsetByPosition(sourcePos: XYPosition, nextPos: XYPosition) {
        return (nextPos.x - sourcePos.x) / 2;
    }

    private calcDefaultXOffset(nodeBefore: Node, nextPos: XYPosition) {
        const beforeX = nodeBefore.position.x + this.getElementWidth(nodeBefore);
        return (nextPos.x - beforeX) / 2;
    }

    private getId(node: Node | undefined): number {
        if (!node) return 0;
        return parseInt(node.id);
    }
}
