import React, { memo } from 'react';
import { EdgeSmoothStepProps, EdgeText, getMarkerEnd, Position } from 'react-flow-renderer';

// Original: https://github.com/wbkd/react-flow/blob/main/src/components/Edges/SmoothStepEdge.tsx

// These are some helper methods for drawing the round corners
// The name indicates the direction of the path. "bottomLeftCorner" goes
// from bottom to the left and "leftBottomCorner" goes from left to the bottom.
// We have to consider the direction of the paths because of the animated lines.
const bottomLeftCorner = (x: number, y: number, size: number): string =>
    `L ${x},${y - size}Q ${x},${y} ${x + size},${y}`;
const leftBottomCorner = (x: number, y: number, size: number): string =>
    `L ${x + size},${y}Q ${x},${y} ${x},${y - size}`;
const bottomRightCorner = (x: number, y: number, size: number): string =>
    `L ${x},${y - size}Q ${x},${y} ${x - size},${y}`;
const rightBottomCorner = (x: number, y: number, size: number): string =>
    `L ${x - size},${y}Q ${x},${y} ${x},${y - size}`;
const leftTopCorner = (x: number, y: number, size: number): string => `L ${x + size},${y}Q ${x},${y} ${x},${y + size}`;
const topLeftCorner = (x: number, y: number, size: number): string => `L ${x},${y + size}Q ${x},${y} ${x + size},${y}`;
const topRightCorner = (x: number, y: number, size: number): string => `L ${x},${y + size}Q ${x},${y} ${x - size},${y}`;
const rightTopCorner = (x: number, y: number, size: number): string => `L ${x - size},${y}Q ${x},${y} ${x},${y + size}`;

interface GetSmoothStepPathParams {
    sourceX: number;
    sourceY: number;
    sourcePosition?: Position;
    targetX: number;
    targetY: number;
    targetPosition?: Position;
    borderRadius?: number;
    centerX?: number;
    centerY?: number;
}

type GetCenterParams = {
    sourceX: number;
    sourceY: number;
    targetX: number;
    targetY: number;
    xOffset: number | undefined;
};

const getCenter = ({
    sourceX,
    sourceY,
    targetX,
    targetY,
    xOffset,
}: GetCenterParams): [number, number, number, number] => {
    const width = targetX < sourceX ? sourceX - targetX : targetX - sourceX;
    const _xOffset = width - (xOffset ?? 0);
    const centerX = targetX < sourceX ? targetX + _xOffset : targetX - _xOffset;

    const yOffset = Math.abs(targetY - sourceY) / 2;
    const centerY = targetY < sourceY ? targetY + yOffset : targetY - yOffset;

    return [centerX, centerY, _xOffset, yOffset];
};

export function getSmoothStepPath(
    {
        sourceX,
        sourceY,
        sourcePosition = Position.Bottom,
        targetX,
        targetY,
        targetPosition = Position.Top,
        borderRadius = 5,
        centerX,
        centerY,
    }: GetSmoothStepPathParams,
    xOffset: number | undefined
): string {
    const [_centerX, _centerY, offsetX, offsetY] = getCenter({ sourceX, sourceY, targetX, targetY, xOffset });
    const cornerWidth = Math.min(borderRadius, Math.abs(targetX - sourceX));
    const cornerHeight = Math.min(borderRadius, Math.abs(targetY - sourceY));
    const cornerSize = Math.min(cornerWidth, cornerHeight, offsetX, offsetY);
    const leftAndRight = [Position.Left, Position.Right];
    const cX = typeof centerX !== 'undefined' ? centerX : _centerX;
    const cY = typeof centerY !== 'undefined' ? centerY : _centerY;

    let firstCornerPath = null;
    let secondCornerPath = null;

    if (sourceX <= targetX) {
        firstCornerPath =
            sourceY <= targetY ? bottomLeftCorner(sourceX, cY, cornerSize) : topLeftCorner(sourceX, cY, cornerSize);
        secondCornerPath =
            sourceY <= targetY ? rightTopCorner(targetX, cY, cornerSize) : rightBottomCorner(targetX, cY, cornerSize);
    } else {
        firstCornerPath =
            sourceY < targetY ? bottomRightCorner(sourceX, cY, cornerSize) : topRightCorner(sourceX, cY, cornerSize);
        secondCornerPath =
            sourceY < targetY ? leftTopCorner(targetX, cY, cornerSize) : leftBottomCorner(targetX, cY, cornerSize);
    }

    if (leftAndRight.includes(sourcePosition) && leftAndRight.includes(targetPosition)) {
        if (sourceX <= targetX) {
            firstCornerPath =
                sourceY <= targetY
                    ? rightTopCorner(cX, sourceY, cornerSize)
                    : rightBottomCorner(cX, sourceY, cornerSize);
            secondCornerPath =
                sourceY <= targetY ? bottomLeftCorner(cX, targetY, cornerSize) : topLeftCorner(cX, targetY, cornerSize);
        }
    } else if (leftAndRight.includes(sourcePosition) && !leftAndRight.includes(targetPosition)) {
        if (sourceX <= targetX) {
            firstCornerPath =
                sourceY <= targetY
                    ? rightTopCorner(targetX, sourceY, cornerSize)
                    : rightBottomCorner(targetX, sourceY, cornerSize);
        } else {
            firstCornerPath =
                sourceY <= targetY
                    ? bottomRightCorner(sourceX, targetY, cornerSize)
                    : topRightCorner(sourceX, targetY, cornerSize);
        }
        secondCornerPath = '';
    } else if (!leftAndRight.includes(sourcePosition) && leftAndRight.includes(targetPosition)) {
        if (sourceX <= targetX) {
            firstCornerPath =
                sourceY <= targetY
                    ? bottomLeftCorner(sourceX, targetY, cornerSize)
                    : topLeftCorner(sourceX, targetY, cornerSize);
        } else {
            firstCornerPath =
                sourceY <= targetY
                    ? bottomRightCorner(sourceX, targetY, cornerSize)
                    : topRightCorner(sourceX, targetY, cornerSize);
        }
        secondCornerPath = '';
    }

    return `M ${sourceX},${sourceY}${firstCornerPath}${secondCornerPath}L ${targetX},${targetY}`;
}

export default memo(
    ({
        sourceX,
        sourceY,
        targetX,
        targetY,
        label,
        labelStyle,
        labelShowBg,
        labelBgStyle,
        labelBgPadding,
        labelBgBorderRadius,
        style,
        sourcePosition = Position.Bottom,
        targetPosition = Position.Top,
        arrowHeadType,
        markerEndId,
        borderRadius = 5,
        data,
    }: EdgeSmoothStepProps) => {
        const centerX = 0;
        const centerY = 0;
        const path = getSmoothStepPath(
            {
                sourceX,
                sourceY,
                sourcePosition,
                targetX,
                targetY,
                targetPosition,
                borderRadius,
            },
            data.xOffset
        );

        const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

        const text = label ? (
            <EdgeText
                x={centerX}
                y={centerY}
                label={label}
                labelStyle={labelStyle}
                labelShowBg={labelShowBg}
                labelBgStyle={labelBgStyle}
                labelBgPadding={labelBgPadding}
                labelBgBorderRadius={labelBgBorderRadius}
            />
        ) : null;

        return (
            <>
                <path style={style} className='react-flow__edge-path' d={path} markerEnd={markerEnd} />
                {text}
            </>
        );
    }
);
